import { ITagEntity } from 'ui/form/TagsInput/TagsInput.types';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { PaginationResponseData } from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import { ITagsRequestData } from './Tag.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TgetMany = (data: ITagsRequestData) => Promise<ITagEntity[]>;

export const getMany: TgetMany = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.get<PaginationResponseData<ITagEntity>>(
		`/rest/${language}/tags`,
		{ params: { ...data } },
	);

	return response.data.items;
};
