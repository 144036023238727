export const AppPaths = {
	root: "/",
	logout: "/logout",
	dashboard: "/dashboard",
	wordList: "/wordlist",
	settings: "/settings",
	help: "/help",
	demo: "/demo",
	crossword: {
		root: "/crossword",
		archive: "/crossword/archive",
		edit: "/crossword/edit/:crosswordId?",
		create: "/crossword/create/:crosswordId?",
		classic: {
			create: "/crossword/classic/create/:crosswordId?",
			edit: "/crossword/classic/edit/:crosswordId?",
		},
		bulk: {
			create: "/crossword/bulk/create/:bulkId?",
			view: "/crossword/bulk/view/:bulkId?",
		},
		codeword: {
			create: "/crossword/codeword/create/:crosswordId?",
			edit: "/crossword/codeword/edit/:crosswordId?",
		},
		combi: {
			create: "/crossword/combi/create/:crosswordId?",
			edit: "/crossword/combi/edit/:crosswordId?",
		},
		multiDifficulty: {
			create: "/crossword/multi-difficulty/create/:crosswordId?",
			edit: "/crossword/multi-difficulty/edit/:crosswordId?",
		},
	},
	puzzleSet: "/puzzle-set/:puzzleIdentifier?",
	languageSwitcher: "/languages",
};
