import { asValue } from 'awilix';

import * as ClueApi from './Clue';
import * as CrosswordApi from './Crossword';
import * as GridPatternApi from './GridPattern';
import * as PublicationApi from './Publication';
import * as TagApi from './Tag';
import * as ThemeApi from './Theme';
import * as UserApi from './User';
import * as WordApi from './Word';
import * as WordClueApi from './WordClue';

export const API_DI = {
	CrosswordApi: asValue(CrosswordApi),
	PublicationApi: asValue(PublicationApi),
	TagApi: asValue(TagApi),
	GridPatternApi: asValue(GridPatternApi),
	WordApi: asValue(WordApi),
	ClueApi: asValue(ClueApi),
	WordClueApi: asValue(WordClueApi),
	UserApi: asValue(UserApi),
	ThemeApi: asValue(ThemeApi),
};
