// import { styled } from "stitches";;
import {styled} from "stitches";

export const Page = styled("div", {
	display: "flex",
	flex: 1,
	flexDirection: "column",
	padding: "$4 $6",
	overflowY: "auto",
	variants: {
		centerContent: {
			true: {
				alignItems: "center",
				justifyContent: "center",
			},
		},
		limitWidth: {
			true: {
				maxWidth: 1000,
			},
		},
	},
});

export const NoResults = styled("div", {
	width: "100%",
	display: "flex",
	justifyContent: "center",
});