import { FC } from 'react';
import { styled } from 'stitches';

import { MenuItem } from '@szhsin/react-menu';
import { CellType } from 'data/Api/GridPattern';
import { IThemeEntity } from 'data/Api/Theme';

const Container = styled('div', {
	display: 'flex',
	flex: 1,
	justifyContent: 'space-between',
	variants: {
		isDisabled: {
			true: {
				opacity: 0.5,
			},
		},
	},
});

const ColorIndicator = styled('div', {
	width: 16,
	height: 16,
	borderRadius: 4,
	boxShadow: 'inset 0 0 0 1px $colors$grayLight',
	variants: {
		isActive: {
			true: {
				boxShadow: 'inset 0 0 0 2px $colors$black',
			},
		},
	},
});

interface IMenuItemThemeColorProps {
	theme: IThemeEntity;
	crosswordThemeId?: string;
	onClick: () => void;
}

export const MenuItemThemeColor: FC<IMenuItemThemeColorProps> = ({
	theme,
	crosswordThemeId,
	onClick,
}) => {
	const isActive = theme.id === crosswordThemeId;

	return (
		<MenuItem onClick={onClick}>
			<Container>
				{theme.name}
				<ColorIndicator
					isActive={isActive}
					style={{
						backgroundColor: theme.data.web.find(
							(item) => item.cellType === CellType.WORD_STREET,
						)?.color,
					}}
				/>
			</Container>
		</MenuItem>
	);
};
