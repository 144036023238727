import {createContext} from "react";
import {MercureStatus} from "./Mercure.constants";
import {IGenerateCrosswordMercureEvent} from "../Crossword/Crossword.types";

export interface ICrosswordMercureContext {
	mercureStatus: MercureStatus;
	mercureMessage: IGenerateCrosswordMercureEvent | null;
	clearMercureMessage: () => void;
}

export const CrosswordMercureContext = createContext<ICrosswordMercureContext>({
	mercureStatus: MercureStatus.disconnected,
	mercureMessage: null,
	clearMercureMessage: () => {
	},
});
