import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
	IWordClueApiCreateRequestData,
	IWordClueApiEntity,
} from './WordClue.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TCreate = (
	data: IWordClueApiCreateRequestData,
) => Promise<IWordClueApiEntity>;

export const create: TCreate = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.post<IWordClueApiEntity>(`/rest/${language}/word-clue`, {
		...data,
	});

	return response.data;
};
