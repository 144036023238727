import { getApiCurrentDate } from 'utils/src/date';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import {
	ICreateGridPatternRequestData,
	IDummyPatternRequestData,
	IGridPatternsRequestData,
	IUpdateGridPatternRequestData,
} from '../../Api/GridPattern';
import { IGridPatternRepository } from './GridPatternRepository.types';

export const GridPatternRepository = (): IGridPatternRepository => {
	const GridPatternApi = DI.resolve('GridPatternApi');

	return {
		async getMany(data: IGridPatternsRequestData) {
			return await GridPatternApi.getMany(data);
		},

		async importFile(data: FormData) {
			return await GridPatternApi.importFile(data);
		},

		async getDummyPattern(data: IDummyPatternRequestData) {
			return await GridPatternApi.getDummyPattern(data);
		},

		async update(id: string, data: IUpdateGridPatternRequestData) {
			return await GridPatternApi.update(id, data);
		},

		async create(data: ICreateGridPatternRequestData) {
			return await GridPatternApi.create(data);
		},

		async deleteOne(pattern) {
			return await this.update(pattern.id, {
				type: pattern.type,
				name: pattern.name,
				data: pattern.data,
				archivedAt: getApiCurrentDate(),
			});
		},
	};
};
