import {FC, PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import {EventSourceMessage, fetchEventSource} from "@microsoft/fetch-event-source";
import {CrosswordMercureContext} from "./CrosswordMercure.context";
import {appEnv} from "../../appEnv";
import {MERCURE_RETRY_INTERVAL, MercureError, MercureStatus} from "./Mercure.constants";
import {flagLogger} from "utils";
import {useCrosswordContext} from "../Crossword/Crossword.hooks";
import {IGenerateCrosswordMercureEvent} from "../Crossword/Crossword.types";

const EventStreamContentType = "text/event-stream";

export const CrosswordMercureProvider: FC<PropsWithChildren> = ({children}) => {
	const mercureInitialized = useRef(false);
	const {setIsGeneratingCrossword} = useCrosswordContext();
	const [mercureStatus, setMercureStatus] = useState<MercureStatus>(
		MercureStatus.disconnected,
	);
	const [mercureMessage, setMercureMessage] = useState<IGenerateCrosswordMercureEvent | null>(null);

	const [processedEvents, setProcessedEvents] = useState<Set<string>>(new Set());

	const abortControllerRef = useRef<AbortController>(new AbortController());
	useEffect(() => () => abortControllerRef.current?.abort(), []);

	const handleOnMessage = useCallback((event: EventSourceMessage) => {
		flagLogger("mercure", ["handleOnMessage", event]);

		if (event.data.length === 0 || processedEvents.has(event.id)) {
			return;
		}

		const eventMessage: IGenerateCrosswordMercureEvent = {
			...event,
			data: JSON.parse(event.data),
		}

		setProcessedEvents((prev) => new Set(prev).add(event.id));
		setMercureMessage(eventMessage);
	}, []);

	const handleOnOpen = useCallback(async (response: Response) => {
		flagLogger("mercure", ["handleOnOpen", response]);

		if (response.status === 401) {
			setMercureStatus(MercureStatus.reconnecting);

			throw new Error(MercureError.UNAUTHORIZED_ERROR);
		}

		const contentType = response.headers.get("content-type");
		if (contentType !== EventStreamContentType && contentType != null) {
			throw new Error(
				`Expected content-type to be ${EventStreamContentType}, Actual: ${contentType}`,
			);
		}

		mercureInitialized.current = true;
		setMercureStatus(MercureStatus.connected);
	}, []);

	const handleOnClose = useCallback(() => {
		flagLogger("mercure", ["handleOnClose"]);

		setMercureStatus(MercureStatus.disconnected);
		mercureInitialized.current = false;
		throw new Error();
	}, [setMercureStatus]);

	const handleOnError = useCallback((err: Error) => {
		flagLogger("mercure", ["handleOnError", err]);

		mercureInitialized.current = false;
		if (err.message === MercureError.UNAUTHORIZED_ERROR) {
			setMercureStatus(MercureStatus.disconnected);

			console.error(err.message);
			throw new Error(err.message);
		}

		return MERCURE_RETRY_INTERVAL;
	}, []);

	const disconnectMercureClient = useCallback(() => {
		flagLogger("mercure", ["disconnectMercureClient"]);
		abortControllerRef.current.abort();
		setMercureStatus(MercureStatus.disconnected);
		mercureInitialized.current = false;
		setProcessedEvents(() => new Set());
		setMercureMessage(null);
	}, []);

	const connectMercureClient = useCallback(() => {
		if (mercureInitialized.current) {
			return;
		}
		flagLogger("mercure", ["Initiate mercure client"]);

		const mercureUrl = new URL(appEnv.MERCURE_PUBLISH_URL);
		mercureUrl.searchParams.append("topic", "/crosswords");

		fetchEventSource(mercureUrl.toString(), {
			headers: {
				Authorization: `Bearer ${appEnv.MERCURE_JWT_TOKEN}`,
				accept: EventStreamContentType,
			},
			signal: abortControllerRef.current.signal,
			onmessage: handleOnMessage,
			onopen: handleOnOpen,
			onclose: handleOnClose,
			onerror: handleOnError,
			openWhenHidden: true,
		}).catch((err) => {
			mercureInitialized.current = false;
			flagLogger("mercure", ["Initiate mercure client | error:", err]);
			abortControllerRef.current.abort();

			setTimeout(() => {
				if (mercureStatus !== MercureStatus.connected) {
					console.log("reconnect!");
				}
			}, MERCURE_RETRY_INTERVAL);
		})
			.finally(() => {
				setIsGeneratingCrossword(() => false);
			});
	}, []);

	const clearMercureMessage = useCallback(() => {
		setMercureMessage(null);
	}, []);

	useEffect(() => {
		connectMercureClient();
		return () => {
			disconnectMercureClient();
		};
	}, []);

	return (
		<CrosswordMercureContext.Provider value={{mercureStatus, mercureMessage, clearMercureMessage}}>
			{children}
		</CrosswordMercureContext.Provider>
	);
};
