// https://zpl.io/L47dKRM
import { createStitches } from '@stitches/react';

export const {
	styled,
	css,
	globalCss: originalGlobalCss,
	keyframes,
	theme,
	createTheme,
	config,
} = createStitches({
	theme: {
		colors: {
			white: '#ffffff',
			black: 'rgb(10, 10, 10)',
			black04: 'rgba(0, 0, 0, 0.04)',
			black08: 'rgba(0, 0, 0, 0.08)',
			black10: 'rgba(10, 10, 10, 0.1)',

			black20: 'rgba(10, 10, 10, 0.2)',
			black60: 'rgba(10, 10, 10, 0.6)',
			black80: 'rgba(10, 10, 10, 0.8)',
			blue25: 'rgba(56, 113, 224, 0.25)',
			blue80: 'rgba(56, 113, 224, 0.8)',
			grayLight: 'rgb(217, 217, 217)',
			grayLight20: 'rgba(217, 217, 217, 0.2)',
			indigo: '#5856cf',
			indigo08: 'rgba(88, 86, 207, 0.08)',
			indigoLight: '#bcbbec',
			indigoDark: '#2a288d',
			green: '#0ff288',
			green60: 'rgba(16, 219, 85, 0.6)',
			greenLight: '#c5fce4',
			greenFoam: '#92fda7',
			yellow: 'rgb(255,178,63)',
			yellow30: 'rgba(255,178,63, 0.3)',
			yellowLight: '#ffeed5',
			red: '#ff5454',
			red40: 'rgba(255, 84, 84, 0.4)',
			red60: 'rgba(255, 84, 84, 0.6)',
			modalBg: '$white',
			keyboardBtnBorder: '$black20',
			toastError: '$black',
			toastSuccess: '$black',
			toastWarning: '$black',
			wordHighLightColor: '#CFCFF2',
			activeCellColor: '#B7B7EB',
		},
		space: {
			1: '8px',
			2: '16px',
			3: '24px',
			4: '32px',
			5: '40px',
			6: '48px',
			7: '56px',
			8: '64px',
		},
		fontSizes: {
			pageTitle: '32px',
			sectionTitle: '24px',
			body: '16px',
			bodySmall: '14px',
			label: '15px',
			buttonText: '16px',
		},
		fonts: {
			regular: 'MonaSans, MonaSans-Fallback, sans-serif',
			keyboardFont: '$regular',
			bold: 'MonaSans-Bold, MonaSans-Fallback, sans-serif',
			semiBold: 'MonaSans-SemiBold, MonaSans-Fallback, sans-serif',
			medium: 'MonaSans-Medium, MonaSans-Fallback, sans-serif',
			clue: 'TektonPro-BoldCond, sans-serif',
		},
		fontWeights: {},
		lineHeights: {},
		letterSpacings: {},
		sizes: {
			1: '8px',
			2: '16px',
			3: '24px',
			4: '32px',
			5: '40px',
			6: '48px',
			7: '56px',
			8: '64px',
		},
		borderWidths: {},
		borderStyles: {},
		radii: {
			small: '4px',
			large: '8px',
			modalRadius: '$large',
			xlarge: '12px',
			half: '50px',
			toastRadius: '$large',
		},
		shadows: {
			errorCell: 'inset 0 0 0px 4px $colors$red60',
			warningCell: 'inset 0 0 0px 4px $colors$yellow',
			radioButton: '0 0 0 2px $indigo',
			small: '0px 0px 3px 0px rgba(0,0,0,0.2)',
			hoverCard:
				'0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 6px 6px 0 rgba(0, 0, 0, 0.09), 0 14px 8px 0 rgba(0, 0, 0, 0.05), 0 24px 10px 0 rgba(0, 0, 0, 0.01), 0 38px 11px 0 rgba(0, 0, 0, 0)',
		},
		zIndices: {
			level1: 1,
			level2: 2,
			level3: 3,
			level4: 4,
			modal: 1000,
		},
		transitions: {},
	},
	media: {
		bp1: '(min-width: 480px)',
		bp2: '(min-width: 740px)',
		bp3: '(min-width: 1024px)',
		bpXl: '(min-width: 1200px)',
	},
	utils: {
		circle: (diameter: number | string) => ({
			width: diameter,
			height: diameter,
			borderRadius: '$half',
		}),
		size: (px: number) => ({
			width: px,
			height: px,
		}),
		truncate: () => ({
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		flexCenter: () => ({
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}),
	},
});
