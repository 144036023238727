import {IFailedGenerationReport} from "crossword-package/CrosswordFailGenerationHandler";

export enum ECrosswordTarget {
	PRINT = "print",
	DIGITAL = "digital",
}

export enum ECrosswordType {
	CLUES_IN_SQUARES = "clues_in_square",
	CLASSIC = "classic",
	CODEWORD = "code_word",
	COMBI = "combi",
	BULK = "bulk",
	MULTI_DIFFICULTY = "multi_diff_clues_in_square",
}

export enum ECrosswordBulkType {
	BULK = "bulk",
	SINGLE = "single",
}

export interface CrosswordTags {
	id: string;
	name: string;
}

export interface IGenerateCrosswordData {
	type: "success" | "error" | "fail" | "started";
	crosswordId?: string;
	crosswordSetId?: string;
	failedGenerationReport?: IFailedGenerationReport;
}

export interface IGenerateCrosswordMercureEvent {
	data: IGenerateCrosswordData;
	event: string;
	id: string;
}

export type TCrosswordStatus =
	"in_progress"
	| "completed"
	| "generation_started"
	| "generation_failed"
	| "generation_succeeded"
	| "in_review"
	| "in_generation_queue";
