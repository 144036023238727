import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IUserApiEntity } from '../../Api/User';

export type TGetSelfUserUseCase = {
	execute(): Promise<IUserApiEntity | undefined>;
};

export const GetSelfUserUseCase = (): TGetSelfUserUseCase => {
	const UserRepository = DI.resolve('UserRepository');

	return {
		async execute() {
			const result = await UserRepository.getSelf();

			return result;
		},
	};
};
