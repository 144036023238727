import {createContext} from "react";
import {
	BulkCrosswordApiResult,
	CreateBulkCrosswordApiOptions,
	UpdateBulkCrosswordApiOptions
} from "./api/BulkCrosswordApi.types";


export interface CrosswordBulkContextProps {
	bulkCrossword: BulkCrosswordApiResult | null;
	bulkIsLoading: boolean;
	createCrosswordBulk: (options: CreateBulkCrosswordApiOptions) => Promise<void>;
	generateCrosswordBulk: (bulkId: string) => Promise<void>;
	getCrosswordBulk: (bulkId: string) => Promise<void>;
	updateCrosswordBulk: (bulkId: string, options: UpdateBulkCrosswordApiOptions) => Promise<void>;
	deleteCrosswordBulk: (bulkId: string) => Promise<void>;
	setSelectedPatterns: (patterns: { id: string, name: string }[]) => void;
	selectedPatterns: { id: string, name: string }[];
	deleteSelectedPattern: (id: string) => void;
	togglePatternSelection: (id: string, name: string) => void;
	exportBulkCrossword: (bulkId: string, crosswordIds: string[]) => Promise<void>;
}

export const BulkCrosswordContext = createContext<CrosswordBulkContextProps>({
	bulkCrossword: null,
	bulkIsLoading: true,
	createCrosswordBulk: async () => {
	},
	generateCrosswordBulk: async () => {
	},
	getCrosswordBulk: async () => {
	},
	updateCrosswordBulk: async () => {
	},
	deleteCrosswordBulk: async () => {
	},
	setSelectedPatterns: () => {
	},
	selectedPatterns: [],
	togglePatternSelection: () => {
	},
	deleteSelectedPattern: () => {
	},
	exportBulkCrossword: async () => {
	},
});
