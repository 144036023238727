import { styled } from 'stitches';

export const StyledFileDrop = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: 200,
	width: '100%',
	border: 'dashed 1px $black20',
	gap: '$2',
	borderRadius: '$small',
	variants: {
		isDragOver: {
			true: {
				backgroundColor: '$indigoLight',
			},
		},
		isDisabled: {
			true: {
				opacity: 0.3,
				backgroundColor: '$grayLight',
			},
		},
		isInactive: {
			true: {
				pointerEvents: 'none',
				display: 'none',
			},
		},
	},
});

export const HelperText = styled('p', {
	fontWeight: 500,
	color: '$black',
	span: {
		fontStyle: 'normal',
		color: '$indigo',
		cursor: 'pointer',
	},
});

export const CustomInfo = styled('p', {
	fontWeight: 500,
	fontSize: '$bodySmall',
	color: '$black60',
});

export const FilesList = styled('ul', {
	listStyle: 'none',
});

export const FileItem = styled('li', {
	backgroundColor: '$grayLight20',
	padding: '$3 $2',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
	borderRadius: '$large',
	'.info': {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flex: 1,
		gap: '$2',
	},
	'.icon': {
		height: 48,
		flexCenter: true,
		marginLeft: '$1',
	},
	'.textContainer': {
		display: 'flex',
		flexDirection: 'column',
	},
	'.fileName': {
		fontSize: '$body',
		fintWeight: 500,
		truncate: true,
	},
	'.fileSize': {
		color: '$black60',
		fontSize: 12,
	},
});
