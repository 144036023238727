import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type ITexportSvg = (
	id: string,
	mode: 'web' | 'print' | 'print?showSolution=1',
) => Promise<any | null>;

export const exportSvg: ITexportSvg = async (id, mode) => {
	const language = getCurrentLanguage();

	const response = await apiClient.get<any>(
		`/rest/${language}/crossword/${id}/export-to-svg/${mode}`,
	);

	return response.data;
};
