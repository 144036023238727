import { styled } from 'stitches';

// TODO: Make a component supporting tooltip question mark
export const Label = styled('label', {
	fontFamily: '$semiBold',
	fontSize: '$body',
	color: '$black',
	whiteSpace: 'nowrap',
	variants: {
		isDisabled: {
			true: {
				opacity: 0.5,
			},
		},
	},
});
