import { ContainerOptions, createContainer } from 'awilix';
import { API_DI } from 'data/Api/ApiDI';
import { RepositoryDI } from 'data/Repository/RepositoryDI';
import { UseCaseDI } from 'data/UseCase/UseCase.DI';
import { ContainerDefinition, TypedAwilixContainer } from './DI.types';
import { DIviewModels } from './sources/DI.ViewModels';

/**
 * Wraps `createContainer` and calls `register` on it.
 */
const createTypedContainer = <T extends ContainerDefinition>(
	registrations: T,
	opts?: ContainerOptions,
): TypedAwilixContainer<T> => {
	return createContainer(opts).register(registrations) as any;
};

const container = createTypedContainer({
	...API_DI,
	...RepositoryDI,
	...UseCaseDI,
	...DIviewModels,
});

export { container };
