import { IUserApiEntity, TUserRole } from 'data/Api/User';
import { IUserPermissions } from './User.types';

export const generateUserPermissions = (
	account: IUserApiEntity | null,
): IUserPermissions => {
	if (!account) return {};

	const isAdmin = account.roles.includes('ROLE_ADMIN');
	const isEditor = account.roles.includes('ROLE_CONTENT_EDITOR');
	const isUser = account.roles.includes('ROLE_USER');

	return {
		canManagePublications: isAdmin,
		canManageWordlist: isAdmin || isEditor,
		canDeleteGridPattern: isAdmin || isEditor,
		canUseEditor: isUser || isEditor || isAdmin,
	};
};

export const generateUserPermissionLanguages = (
	account: IUserApiEntity | null,
): string[] => {
	if (!account) return [];

	const languagesMap: { [key in TUserRole]?: string } = {
		ROLE_NO_LANGUAGE: 'no',
		ROLE_DK_LANGUAGE: 'dk',
		ROLE_SE_LANGUAGE: 'se',
		ROLE_EN_LANGUAGE: 'en'
	};

	const languages: string[] = [];

	account.roles.forEach(role => {
		const language = languagesMap[role];
		if (language && !languages.includes(language)) {
			languages.push(language);
		}
	});

	return languages;
};

export const getUserById = (users: IUserApiEntity[], id: string) => {
	const user = users.find((user) => user.id === id);

	if (user) {
		const { firstName, lastName } = user;
		return firstName && lastName
			? `${firstName} ${lastName}`
			: firstName || lastName;
	}

	return 'Unknown';
};
