import {IGridPatternApiEntity, IGridPatternsRequestData,} from "data/Api/GridPattern";
import {debounce} from "lodash";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {TSelectOptions} from "ui/form";
import {DI} from "../../../../../shared/di";
import {PaginationInfo} from "../../../../../shared/types/RestApi.types";
import {GRID_RECOMMENDED_DIGITAL_HEIGHT, GRID_RECOMMENDED_DIGITAL_WIDTH,} from "../../../Crossword.constants";
import {useCrosswordContext} from "../../../Crossword.hooks";
import {ECrosswordTarget, ECrosswordType} from "../../../Crossword.types";
import {initialFilterData, sortOptions} from "./ConfigureLayout.constants";
import {IConfigureLayoutContext} from "./ConfigureLayout.context";
import {useLocalStorage} from "utils/hooks";
import {updateCrossword} from "../../../Api/UpdateCrossword.api";
import {useBulkCrosswordContext} from "../../../../BulkCrossword/BulkCrossword.hooks";
import {getCrosswordEditUrl} from "./ConfigureLayout.utils";

export interface IViewModelReturn {
	configureLayoutContextValue: IConfigureLayoutContext;
	selectedPattern?: IGridPatternApiEntity;
}

export const ConfigureLayoutViewmodel = (): IViewModelReturn => {
	const GetManyGridPatternUseCase = DI.resolve("GetManyGridPatternUseCase");

	const navigate = useNavigate();
	const {setItem, getItem} = useLocalStorage();

	const {currentCrossword} = useCrosswordContext();
	const {bulkCrossword} = useBulkCrosswordContext();

	const [isLoading, setIsLoading] = useState(false);
	const [isAddingGridPattern, setIsAddingGridPattern] = useState(false);
	const [queryData, setQueryData] = useState<
		IGridPatternsRequestData | undefined
	>(undefined);
	const [items, setItems] = useState<IGridPatternApiEntity[]>([]);

	const [selectedPattern, setSelectedPattern] = useState<
		IGridPatternApiEntity | undefined
	>(undefined);
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		page: 0,
		pageSize: 0,
		totalItemCount: 0,
	});

	const canLoadMore = useMemo(
		() => paginationInfo.totalItemCount > items.length,
		[paginationInfo, items],
	);

	/**
	 * Initial fetching of patterns from the API based on filters
	 */
	const updateFilters = useCallback(
		debounce(async (data: IGridPatternsRequestData) => {
			setQueryData(data);

			setIsLoading(true);
			const {items: freshItems, paginationInfo: freshPaginationInfo} =
				await GetManyGridPatternUseCase.execute({
					// pageSize: 1, // <- to test load more
					...data,
					type: data.type === ECrosswordType.COMBI || data.type === ECrosswordType.MULTI_DIFFICULTY
						? ECrosswordType.CLUES_IN_SQUARES
						: data.type,
					image: data.image === true ? 1 : 0,
					filterOwn: data.filterOwn === true ? 1 : 0,
				});

			if (freshItems) {
				setIsLoading(false);
				setItems(freshItems);
				setPaginationInfo(freshPaginationInfo);
			}
		}, 700),
		[],
	);

	/**
	 * Fetch more patterns from the API
	 */
	const fetchMore = useCallback(async () => {
		if (items.length < paginationInfo.totalItemCount && queryData) {
			if (!canLoadMore) return;

			setIsLoading(true);
			const {items: freshItems, paginationInfo: freshPaginationInfo} =
				await GetManyGridPatternUseCase.execute({
					// pageSize: 1, // <- to test load more
					page: paginationInfo.page + 1,
					...queryData,
					type: queryData.type === ECrosswordType.COMBI || queryData.type === ECrosswordType.MULTI_DIFFICULTY
						? ECrosswordType.CLUES_IN_SQUARES
						: queryData.type,
					image: queryData.image === true ? 1 : 0,
					filterOwn: queryData.filterOwn === true ? 1 : 0,
				});

			if (freshItems) {
				setIsLoading(false);
				setItems([...items, ...freshItems]);
				setPaginationInfo(freshPaginationInfo);
			}
		}
	}, [queryData, items, paginationInfo, canLoadMore]);

	/**
	 * Add the selected pattern to the current crossword and moves on to the editor
	 */
	const addGridPattern = useCallback(async () => {
		if (!currentCrossword || !selectedPattern || isAddingGridPattern) return;
		setIsAddingGridPattern(true);

		const result = await updateCrossword(currentCrossword?.id, {
			difficultyFrom: currentCrossword.difficultyFrom,
			difficultyTo: currentCrossword.difficultyTo,
			target: currentCrossword.target,
			type: currentCrossword.type,
			name: currentCrossword.name,
			publicationId: currentCrossword.publicationId,
			gridPatternData: selectedPattern,
			themeId: currentCrossword.themeId,
			isCampaign: currentCrossword.isCampaign,
			generationSettings: currentCrossword.generationSettings,
		});

		if (result) {
			const url = getCrosswordEditUrl(currentCrossword.type, currentCrossword.id)
			navigate(url);
		} else {
			setIsAddingGridPattern(false);
		}
	}, [currentCrossword, selectedPattern]);

	useEffect(() => {
		if (selectedPattern) {
			addGridPattern();
		}
	}, [selectedPattern, addGridPattern]);

	/**
	 * Filter form data
	 */

	let filterOwn = 0;
	let filterImage = 0;

	try {
		filterOwn = currentCrossword?.type === ECrosswordType.CLUES_IN_SQUARES
			? getItem("create_crossword_filter_own") ? 1 : 0
			: 0;
		filterImage = currentCrossword?.type === ECrosswordType.CLUES_IN_SQUARES
			? (currentCrossword?.target === ECrosswordTarget.DIGITAL
				? 0
				: getItem("create_crossword_filter_image") ? 1 : 0)
			: 0;
	} catch (e) {
		console.error("Error parsing filter data", e);
	}

	const defaultValues = useMemo(
		() => ({
			...initialFilterData,
			type: currentCrossword?.type ?? bulkCrossword?.settings.type ?? ECrosswordType.CLUES_IN_SQUARES,
			filterOwn: filterOwn === 1,
			image: filterImage === 1,
			maxWidth:
				currentCrossword?.target === ECrosswordTarget.DIGITAL
					? GRID_RECOMMENDED_DIGITAL_WIDTH
					: initialFilterData.maxWidth,
			maxHeight:
				currentCrossword?.target === ECrosswordTarget.DIGITAL
					? GRID_RECOMMENDED_DIGITAL_HEIGHT
					: initialFilterData.maxHeight,
			minHeight:
				(currentCrossword?.type === ECrosswordType.MULTI_DIFFICULTY
					? currentCrossword.generationSettings?.difficultyAreas?.length
					: bulkCrossword?.settings.type === ECrosswordType.MULTI_DIFFICULTY
						? bulkCrossword.settings.generationSettings?.difficultyAreas?.length
						: initialFilterData.minHeight) || 1,
		}),
		[currentCrossword, bulkCrossword],
	);

	const {watch, ...form} = useForm<
		IGridPatternsRequestData & { sortBy: TSelectOptions }
	>({
		defaultValues,
		mode: "onBlur",
	});

	// on change of form values, call the use case for getting grid patterns
	useEffect(() => {
		const subscription = watch((value) => {
			const purgedValue = {} as any;

			// since we have the sortBy select which is not part of the request data, we need to remove it
			// and also remove any empty values
			for (const [key, val] of Object.entries(value)) {
				if (val !== "" && key !== "sortBy") {
					purgedValue[key as keyof IGridPatternsRequestData] = val as any;
				}
			}

			const filterOwn = purgedValue.filterOwn !== undefined && purgedValue.filterOwn !== 0
				? `${purgedValue.filterOwn}`
				: getItem("create_crossword_filter_own") ?? "0";

			const image = purgedValue.image !== undefined && purgedValue.image !== 0
				? `${purgedValue.image}`
				: getItem("create_crossword_filter_image") ?? "0";

			setItem("create_crossword_filter_own", filterOwn);
			setItem("create_crossword_filter_image", image);

			updateFilters(purgedValue as IGridPatternsRequestData);
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const handleSortByChange = useCallback(
		(value: string) => {
			if (!form?.setValue) {
				return;
			}
			const match = sortOptions.find((option) => option.value === value);
			if (match) {
				form?.setValue("sort", match.sort);
				form?.setValue("direction", match.direction);
			}
		},
		[form],
	);

	/**
	 * Do initial filtering on mount
	 */
	useEffect(() => {
		updateFilters(defaultValues);
	}, []);

	const configureLayoutContextValue: IConfigureLayoutContext = useMemo(
		() => ({
			isAddingGridPattern,
			filterForm: form,
			items,
			setItems,
			paginationInfo,
			setPaginationInfo,
			fetchMore,
			isLoading,
			canLoadMore,
			selectedPattern,
			setSelectedPattern,
			handleSortByChange,
		}),
		[
			isAddingGridPattern,
			form,
			items,
			setItems,
			paginationInfo,
			setPaginationInfo,
			fetchMore,
			isLoading,
			canLoadMore,
			selectedPattern,
			setSelectedPattern,
			handleSortByChange,
		],
	);

	return {
		configureLayoutContextValue,
		selectedPattern,
	};
};
