import {normalize} from "stitches-normalize-css";
import {originalGlobalCss} from "./stitches.config";

export const globalCss = originalGlobalCss({
	...normalize,
	"@font-face": [
		{
			fontFamily: "MonaSans-Fallback",
			sizeAdjust: "108.5%",
			ascentOverride: "82%",
			src: "local(Arial)",
		},
	],
	"*, *:before, *:after": {
		margin: 0,
		padding: 0,
		boxSizing: "border-box",
		WebkitTapHighlightColor: "transparent",
	},
	"*:focus": {
		outline: "none",
	},
	html: {
		fontFamily: "MonaSans",
		scrollBehavior: "smooth",
		MozOsxFontSmoothing: "grayscale",
		WebkitFontSmoothing: "antialiased",
		textRendering: "optimizeLegibility",
		maxHeight: "100vh",
		height: "100vh",
	},
	body: {
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		scrollbarWidth: "thin",
		scrollbarColor: "$indigoLight transparent",
	},
	"#root": {
		height: "100vh",
		display: "flex",
		flexDirection: "column",
	},
	h2: {
		fontFamily: "$primary",
		fontSize: "$pageTitle",
		fontWeight: "bold",
	},
	h3: {
		fontSize: "$sectionTitle",
		fontWeight: 500,
	},
	"*::-webkit-scrollbar": {
		width: 6,
		height: 6,
	},
	"*::-webkit-scrollbar-track, *::-webkit-scrollbar-corner": {
		background: "transparent",
	},
	"*::-webkit-scrollbar-thumb": {
		borderRadius: 3,
		background: "$indigoDark",
		transition: "0.2s background ease-out",
	},
	".tooltip": {
		zIndex: "$modal",
	},
});
