import { database } from '../../../../apps/crossword-maker/src/Database/Database';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TDeleteOneCrosswordUseCase = {
	execute(id: string): Promise<{ ok: boolean }>;
};

export const DeleteOneCrosswordUseCase = (): TDeleteOneCrosswordUseCase => {
	const CrosswordRepository = DI.resolve('CrosswordRepository');

	return {
		async execute(id) {
			const result = await CrosswordRepository.deleteOne(id);

			if (result.ok) {
				// todo: the datasource needs to be divided between success and failure
				// since it'll return an empty object
				database.crossword.delete(id);
			}

			return result;
		},
	};
};
