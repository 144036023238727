import {DI} from "../../../../apps/crossword-maker/src/shared/di";
import {IWordRepository, ReplaceWordDataType} from "./WordRepository.types";

export const WordRepository = (): IWordRepository => {
	const WordApi = DI.resolve("WordApi");

	return {
		async getMany(data) {
			return await WordApi.getMany(data);
		},

		async update(id, data) {
			return await WordApi.update(id, data);
		},

		async create(data) {
			return await WordApi.create(data);
		},

		async addTags(id, data) {
			return await WordApi.addTags(id, data);
		},

		async getTags(id) {
			return await WordApi.getTags(id);
		},

		async deleteOne(id) {
			return await WordApi.deleteOne(id);
		},

		async replaceWord(data: ReplaceWordDataType) {
			return await WordApi.wordReplace(data);
		}

		// async archiveOne(word) {
		// 	return await this.update(word.id, {
		// 		word: word.word,
		// 		archivedAt: new Date().toISOString(),
		// 	});
		// },
	};
};
