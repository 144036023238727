import {DI} from "../../../../apps/crossword-maker/src/shared/di";

export type TExportUsedWordsCrosswordToTxtUseCase = {
	execute(
		id: string,
	): Promise<any | null>;
};

export const ExportUsedWordsCrosswordToTxtUseCase = (): TExportUsedWordsCrosswordToTxtUseCase => {
	const CrosswordRepository = DI.resolve("CrosswordRepository");

	return {
		async execute(id) {
			return await CrosswordRepository.exportUsedWordsToTxt(id);
		},
	};
};
