import {PatternData} from "data/Api/GridPattern";

export const clearGridPatternData = (gridPatternData: PatternData[][]) => {
	return gridPatternData.map((row) => {
		return row.map((cell) => {
			return {
				...cell,
				clue: undefined,
				cellNumber: cell.number,
			};
		});
	});
};

export const purgeGridPatternData = (patternData: PatternData[][]) => {
	if (!patternData) return [];

	return patternData.map((row) => {
		return row.map((cell) => {
			return {
				...cell,
				char: !cell.revealed ? undefined : cell.char,
			};
		});
	});
};

export const clearGridPatternDataNumbers = (gridPatternData: PatternData[][]) => {
	return gridPatternData.map((row) => {
		return row.map((cell) => {
			return {
				...cell,
				number: undefined,
			};
		});
	});
}

export const fullresetGridPatternData = (gridPatternData: PatternData[][]) => {
	return gridPatternData.map((row) => {
		return row.map((cell) => {
			return {
				...cell,
				clue: undefined,
				number: undefined,
				char: undefined,
				revealed: false,
			};
		});
	});
}