import { FC, PropsWithChildren } from 'react';
import { styled } from 'stitches';

const StyledItem = styled('li', {
	'[role="group"]': {
		width: '100%',
	},
});

export const MenuItemNonInteractive: FC<PropsWithChildren> = ({ children }) => {
	return (
		<StyledItem role='menuitem' tabIndex={-1} className='szh-menu__item'>
			{children}
		</StyledItem>
	);
};
