import {forwardRef} from "react";
import ReactSelect from "react-select";
import StateManagedSelect, {ActionMeta, CSSObjectWithLabel, SingleValue} from "react-select";
import {theme} from "stitches";
import {reactSelectSharedControlStyles, reactSelectSharedMenuStyles, SelectWrapper,} from "./Select.styles";
import {ISelectOption, TSelectOptions} from "./Select.types";

interface SelectProps {
	options: TSelectOptions;
	placeholder?: string;
	defaultValue?: ISelectOption;
	isSearchable?: boolean;
	disabled?: boolean;
	width?: number | string;
	height?: number | string;
	connectedRight?: boolean;
	value?: SingleValue<ISelectOption>;
	onChange?: (
		newValue: SingleValue<ISelectOption>,
		actionMeta: ActionMeta<ISelectOption>,
	) => void;
	cssObjectWithLabel?: CSSObjectWithLabel;
	menuCssObjectWithLabel?: CSSObjectWithLabel;
	menuPlacement?: "auto" | "bottom" | "top";
}

export const Select = forwardRef<StateManagedSelect, SelectProps>(
	(
		{
			isSearchable,
			width = "100%",
			height,
			connectedRight,
			value = undefined,
			disabled = false,
			cssObjectWithLabel = reactSelectSharedControlStyles,
			menuCssObjectWithLabel = reactSelectSharedMenuStyles,
			menuPlacement,
			...props
		},
		ref,
	) => {
		const placementTop = menuPlacement === "top";

		return (
			<SelectWrapper css={{width, height}}>
				<ReactSelect
					ref={() => ref}
					{...props}
					isDisabled={disabled}
					value={value}
					isSearchable={isSearchable ?? false}
					openMenuOnFocus={true}
					menuPlacement={menuPlacement}
					styles={{
						control: (baseStyles, {menuIsOpen, isDisabled}) => ({
							...baseStyles,
							...cssObjectWithLabel,
							backgroundColor: isDisabled
								? theme.colors.black10.toString()
								: "unset",
							borderWidth: menuIsOpen ? 2 : 1,
							borderBottomLeftRadius: (menuIsOpen && !placementTop)
								? 0
								: theme.radii.large.toString(),
							borderBottomRightRadius:
								(menuIsOpen && !placementTop) || connectedRight ? 0 : theme.radii.large.toString(),
							borderTopRightRadius: connectedRight || (menuIsOpen && placementTop)
								? 0
								: theme.radii.large.toString(),
							borderTopLeftRadius: (menuIsOpen && placementTop)
								? 0
								: theme.radii.large.toString(),
						}),
						menu: (baseStyles) => ({
							...baseStyles,
							...menuCssObjectWithLabel,
							marginTop: placementTop ? 0 : -2,
							marginBottom: placementTop ? -2 : 0,
							boxShadow: "unset",
							borderTop: `${placementTop ? "2px" : "1px"} solid ${theme.colors.black.toString()}`,
							borderLeft: `2px solid ${theme.colors.black.toString()}`,
							borderRight: `2px solid ${theme.colors.black.toString()}`,
							borderBottom: `${placementTop ? "1px" : "2px"} solid ${theme.colors.black.toString()}`,
							borderTopLeftRadius: placementTop ? 8 : 0,
							borderTopRightRadius: placementTop ? 8 : 0,
							borderBottomLeftRadius: placementTop ? 0 : 8,
							borderBottomRightRadius: placementTop ? 0 : 8,
							paddingBottom: placementTop ? 0 : 3,
							paddingTop: placementTop ? 3 : 0,
						}),
						menuList: (baseStyles) => ({
							...baseStyles,
							borderRadius: 8,
							maxHeight: 48 * 5,
						}),
						option: (baseStyles, {isSelected, isFocused}) => ({
							...baseStyles,
							backgroundColor: isSelected
								? theme.colors.indigoLight.toString()
								: isFocused
									? theme.colors.grayLight.toString()
									: theme.colors.white.toString(),
							color: theme.colors.black.toString(),
							height: 48,
							display: "flex",
							alignItems: "center",
							":hover": {
								backgroundColor: isSelected
									? theme.colors.indigoLight.toString()
									: theme.colors.grayLight.toString(),
							},
						}),
						dropdownIndicator: (provided) => ({
							...provided,
							color: theme.colors.black60.toString(),
							padding: 2,
						}),
						indicatorSeparator: (provided) => ({
							...provided,
							display: "none",
						}),
					}}
				/>
			</SelectWrapper>
		);
	},
);
