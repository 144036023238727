import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
	PaginationInfo,
	PaginationResponseData,
} from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import {
	ICrosswordApiEntity,
	ICrosswordsApiRequestData,
} from './Crossword.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TGetMany = (data?: ICrosswordsApiRequestData) => Promise<{
	items: ICrosswordApiEntity[];
	paginationInfo: PaginationInfo;
}>;

export const getMany: TGetMany = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.get<
		PaginationResponseData<ICrosswordApiEntity>
	>(`/rest/${language}/crosswords`, { params: { ...data } });

	return {
		items: response.data.items,
		paginationInfo: response.data.paginationInfo,
	};
};
