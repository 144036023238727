import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import {
	IGridPatternApiEntity,
	ImportGridPatternRequestData,
} from '../../Api/GridPattern';

export type TImportFileGridPatternUseCase = {
	execute(data: ImportGridPatternRequestData): Promise<IGridPatternApiEntity>;
};

export function ImportFileGridPatternUseCase(): TImportFileGridPatternUseCase {
	const GridPatternRepository = DI.resolve('GridPatternRepository');

	return {
		async execute(data) {
			const { files, type, name, description } = data;

			// this use case only supports one file, so let's make sure we only have one
			const formData = new FormData();
			formData.append('file', files[0]);
			formData.append('type', type);
			formData.append('name', name);
			if (description) {
				formData.append('description', description);
			}

			return await GridPatternRepository.importFile(formData);
		},
	};
}
