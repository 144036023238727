import {CSSObjectWithLabel} from "react-select";
import {styled, theme} from "stitches";

export const SelectWrapper = styled("div", {
	position: "relative",
	width: "100%",
	"&:hover": {
		".caret": {
			top: "53%",
			transition: "top 0.2s ease-in-out",
		},
	},
});

/**
 * react-select styles
 */

export const reactSelectSharedControlStyles: CSSObjectWithLabel = {
	backgroundColor: "unset",
	borderColor: theme.colors.black.toString(),
	borderRadius: theme.radii.large.toString(),
	textAlign: "left",
	padding: `0 ${theme.space[1].toString()}`,
	boxShadow: "none",
	minHeight: 48,
	"&:hover": {
		borderColor: theme.colors.black.toString(),
	},
}

export const editClueReactSelectSharedControlStyles = {
	...reactSelectSharedControlStyles,
	border: "1px solid rgba(0, 0, 0, 0.12)",
	minHeight: 40,
}

export const reactSelectSharedMenuStyles: CSSObjectWithLabel = {
	zIndex: theme.zIndices.level4.toString(),
	backgroundColor: theme.colors.white.toString(),
};

export const reactSelectSharedOptionStyles: CSSObjectWithLabel = {
	...reactSelectSharedControlStyles,
	padding: 0,
	border: "1px solid rgb(184, 184, 184)",
	height: 27,
	minHeight: "unset",
	width: 45,
	marginLeft: 5,
	marginRight: 0,
	borderRadius: "5px !important",
}

export const customClueReactSelectSharedMenuStyles: CSSObjectWithLabel = {
	width: 45,
	marginLeft: 10,
	marginTop: "-3px !important",
	border: "1px solid rgb(184, 184, 184) !important",
	borderTop: "none !important",
	borderRadius: "0 !important",
}