import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IWordApiCreatePayload, IWordApiEntity } from './Word.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TCreate = (data: IWordApiCreatePayload) => Promise<IWordApiEntity>;

export const create: TCreate = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.post<IWordApiEntity>(`/rest/${language}/word`, {
		...data,
	});

	return response.data;
};
