import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext } from 'react';
import { flagLogger } from 'utils';
import { AppPaths } from '../App/App.constant';
import { AuthContext, IAuthContext } from './Auth.context';
import { removeToken, setToken } from './Auth.utils';

export const useAuthContext = (): IAuthContext => {
	return useContext(AuthContext);
};

export const useAuth = () => {
	const {
		isAuthenticated,
		getAccessTokenSilently,
		logout: auth0logout,
	} = useAuth0();

	const getToken = useCallback(async () => {
		if (!isAuthenticated) {
			removeToken();
		}

		const token = await getAccessTokenSilently();

		if (token) {
			flagLogger('auth', ['token', token]);
			setToken(token);
			return token;
		} else {
			removeToken();
			return null;
		}
	}, [isAuthenticated]);

	const logout = useCallback(() => {
		auth0logout({
			logoutParams: {
				returnTo: `${window.location.origin}${AppPaths.logout}`,
			},
		});
	}, []);

	return {
		getToken,
		logout,
	};
};
