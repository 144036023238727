import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IWordClueRepository } from './WordClueRepository.types';

export const WordClueRepository = (): IWordClueRepository => {
	const WordClueApi = DI.resolve('WordClueApi');
	const ClueRepository = DI.resolve('ClueRepository');

	return {
		async create(data) {
			return await WordClueApi.create(data);
		},

		async createWithClue(wordId, clueText, difficulty) {
			const existingClue = await ClueRepository.getMany({ clue: clueText });

			if (existingClue[0]?.id) {
				// clue exists -> create word clue relationship
				const newWordClue = await this.create({
					wordId,
					clueId: existingClue[0].id,
					difficulty,
				});

				return newWordClue;
			} else {
				// create clue first
				const newClue = await ClueRepository.create({ clue: clueText, approved: true });

				// create word clue relationship with new clue
				if (newClue) {
					const newWordClue = await this.create({
						wordId,
						clueId: newClue.id,
						difficulty,
					});

					return newWordClue;
				} else {
					throw new Error('Could not create clue');
				}
			}
		},

		async update(id, data) {
			return await WordClueApi.update(id, data);
		},

		async deleteOne(id) {
			return await WordClueApi.deleteOne(id);
		},
	};
};
