import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IWordApiEntity, IWordApiUpdatePayload } from '../../Api/Word';

export type TUpdateWordUseCase = {
	execute(id: string, data: IWordApiUpdatePayload): Promise<IWordApiEntity>;
};

export const UpdateWordUseCase = (): TUpdateWordUseCase => {
	const WordRepository = DI.resolve('WordRepository');

	return {
		async execute(id, data) {
			return await WordRepository.update(id, data);
		},
	};
};
