import { ITagsInputOption } from 'ui/form';
import { ITagEntity } from 'ui/form/TagsInput/TagsInput.types';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type Tcreate = (tag: ITagsInputOption) => Promise<ITagEntity>;

export const create: Tcreate = async (tag) => {
	const language = getCurrentLanguage();

	const response = await apiClient.post<ITagEntity>(`/rest/${language}/tag`, {
		name: tag.value,
	});

	const tagData = response.data;

	return tagData;
};
