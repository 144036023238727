import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IWordApiAddTagsPayload } from '../../Api/Word';

export type TAddTagsWordUseCase = {
	execute(
		id: string,
		data: IWordApiAddTagsPayload,
	): Promise<{ success: boolean }>;
};

export const AddTagsWordUseCase = (): TAddTagsWordUseCase => {
	const WordRepository = DI.resolve('WordRepository');

	return {
		async execute(id, data) {
			const result = await WordRepository.addTags(id, data);

			return result;
		},
	};
};
