import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IClueApiEntity, IUpdateClueRequestData } from '../../Api/Clue';

export type TUpdateClueUseCase = {
	execute(id: string, data: IUpdateClueRequestData): Promise<IClueApiEntity>;
};

export const UpdateClueUseCase = (): TUpdateClueUseCase => {
	const ClueRepository = DI.resolve('ClueRepository');

	return {
		async execute(id, data) {
			return await ClueRepository.update(id, data);
		},
	};
};
