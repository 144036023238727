import { ITagEntity, ITagsInputOption } from 'ui/form';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TCreateTagUseCase = {
	execute(data: ITagsInputOption): Promise<ITagEntity>;
};

export const CreateTagUseCase = (): TCreateTagUseCase => {
	const TagRepository = DI.resolve('TagRepository');

	return {
		async execute(data) {
			return await TagRepository.create(data);
		},
	};
};
