import { styled } from 'stitches';

export const StyledErrorFallback = styled('div', {
	backgroundColor: '$red',
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	gap: '$space$4',
	flexCenter: true,
	position: 'relative',
	padding: '$space$4',
	margin: '$space$2',
	borderRadius: '$radii$large',
	overflow: 'hidden',
	'&::before': {
		content: '',
		position: 'absolute',
		top: 0,
		left: 0,
		width: 'calc(100% + $space$6)',
		height: '100%',
		opacity: 0.3,
		background: 'linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%)',
	},
});

export const Title = styled('h3', {
	fontSize: 20,
	color: '$black80',
	opacity: 1,
});

export const Content = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	minWidth: '50%',
	margin: '$space$4',
	backgroundColor: '$white',
	padding: '$space$4',
	borderRadius: '$radii$large',
	zIndex: '$level1',
	maxWidth: '80%',
	pre: {
		margin: '$2 0',
		padding: '$1',
		borderRadius: '$radii$small',
		backgroundColor: '$grayLight',
		fontFamily: 'monospace',
		whiteSpace: 'break-spaces',
	},
});

export const FaceContainer = styled('div', {
	display: 'flex',
	position: 'relative',
	'&::before': {
		content: '',
		position: 'absolute',
		width: 80,
		height: 12,
		background: '$black20',
		left: '00%',
		top: '95%',
		borderRadius: '50%',
		zIndex: 1,
	},
});

export const Face = styled('div', {
	position: 'relative',
	width: 80,
	height: 80,
	backgroundColor: '$white',
	borderRadius: '$half',
	border: '1px solid $black',
	zIndex: 2,
	'.eye': {
		position: 'absolute',
		width: 10,
		height: 10,
		background: '$black80',
		borderRadius: '$half',
		top: '40%',
		left: '20%',
	},
	'.eye.right': {
		left: '68%',
	},
	'.mouth': {
		position: 'absolute',
		left: '41%',
		width: 14,
		height: 14,
		borderRadius: '50%',
		top: '59%',
		border: '2px solid',
		borderColor: '$black80 transparent transparent $black80',
		transform: 'rotate(45deg)',
	},
});
