import { Auth0Provider } from '@auth0/auth0-react';
import { FC, PropsWithChildren } from 'react';
import { appEnv } from '../../appEnv';
import { UserProvider } from '../User/User.provider';
import { AuthProvider } from './Auth.provider';

export const Auth: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Auth0Provider
			domain={appEnv.AUTH0_DOMAIN}
			clientId={appEnv.AUTH0_CLIENT_ID}
			authorizationParams={{
				redirect_uri: `${window.location.origin}`,
				audience: appEnv.AUTH0_AUDIENCE,
				scope: 'openid name email nickname profile',
			}}
		>
			<AuthProvider>
				<UserProvider>{children}</UserProvider>
			</AuthProvider>
		</Auth0Provider>
	);
};
