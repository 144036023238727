import { CSS } from '@stitches/react';
import { ButtonHTMLAttributes, FC, forwardRef } from 'react';
import { Spinner } from '../spinner';
import { SpinnerContainer, StyledButton, TextContainer } from './Button.styled';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	displaySpinner?: boolean;
	mode?:
		| 'primary'
		| 'link'
		| 'secondary'
		| 'tertiary'
		| 'danger'
		| 'floatingAction';
	size?: 'large' | 'default' | 'tight' | 'compact' | 'medium';
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	css?: CSS;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
	({ children, displaySpinner = false, disabled = false, ...props }, ref) => {
		return (
			<StyledButton {...props} disabled={disabled || displaySpinner} ref={ref}>
				<TextContainer displaySpinner={displaySpinner}>
					{children}
				</TextContainer>
				{displaySpinner && (
					<SpinnerContainer>
						<Spinner />
					</SpinnerContainer>
				)}
			</StyledButton>
		);
	},
);
