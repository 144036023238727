import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import type { IPublicationRepository } from './PublicationRepository.types';

export const PublicationRepository = (): IPublicationRepository => {
	const PublicationApi = DI.resolve('PublicationApi');

	return {
		async create(name: string) {
			return await PublicationApi.create(name);
		},

		async getMany() {
			return await PublicationApi.getMany();
		},
	};
};
