import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TGetCrosswordSolutionWordsUseCase = {
	execute(ids: string[]): Promise<any | null>;
};

export const GetCrosswordSolutionWordsUseCase =
	(): TGetCrosswordSolutionWordsUseCase => {
		const CrosswordRepository = DI.resolve('CrosswordRepository');

		return {
			async execute(ids) {
				const result = await CrosswordRepository.getCrosswordSolutionWords(ids);
				return result;
			},
		};
	};
