import { MenuProps as ReactMenuProps } from '@szhsin/react-menu';
import { FC, PropsWithChildren } from 'react';
import { StyledMenu } from './Menu.styles';

interface MenuProps extends PropsWithChildren {
	triggerElement: React.ReactElement<
		any,
		string | React.JSXElementConstructor<any>
	>;
	align?: ReactMenuProps['align'];
	position?: ReactMenuProps['position'];
	size?: 'fit' | 'small' | 'large';
}

export const Menu: FC<MenuProps> = ({
	children,
	triggerElement,
	size = 'fit',
}) => {
	if (!children) return null;

	return (
		<StyledMenu
			menuButton={triggerElement}
			direction='bottom'
			align='end'
			position='auto'
			gap={4}
			transition
			size={size}
		>
			{children}
		</StyledMenu>
	);
};
