import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TDeleteWordUseCase = {
	execute(id: string): Promise<{ success: boolean }>;
};

export const DeleteWordUseCase = (): TDeleteWordUseCase => {
	const WordRepository = DI.resolve('WordRepository');

	return {
		async execute(id) {
			const result = await WordRepository.deleteOne(id);

			return result;
		},
	};
};
