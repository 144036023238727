import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IWordApiRequestData, IWordApiResponse } from '../../Api/Word';

export type TGetManyWordUseCase = {
	execute(data?: IWordApiRequestData): Promise<IWordApiResponse>;
};

export const GetManyWordUseCase = (): TGetManyWordUseCase => {
	const WordRepository = DI.resolve('WordRepository');

	return {
		async execute(data) {
			const result = await WordRepository.getMany(data);

			return result;
		},
	};
};
