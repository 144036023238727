import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { styled } from 'stitches';

const StyledButtonWithIcon = styled('button', {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	border: '2px solid transparent',
	outline: 'none',
	cursor: 'pointer',
	backgroundColor: 'transparent',
	color: '$black',
	borderRadius: '$small',
	transition: 'border 0.2s ease-in-out',
	'.button-text': {
		fontFamily: '$regular',
		fontSize: '$body',
		marginLeft: '$1',
	},
	'&:hover': {
		border: '2px solid $black',
	},
	'&:disabled': {
		backgroundColor: '$grayLight',
		opacity: 0.5,
		cursor: 'not-allowed',
	},
	variants: {
		dim: {
			true: {
				opacity: 0.3,
			},
		},
		rounded: {
			true: {
				borderRadius: '$large',
			},
		},
		border: {
			true: {
				border: '2px solid $black08',
			},
		},
		hasText: {
			true: {
				padding: '0 $2',
				minHeight: 48,
			},
		},
		size: {
			small: {
				minHeight: 32,
				minWidth: 32,
			},
			medium: {
				minHeight: 40,
				minWidth: 40,
			},
			large: {
				minHeight: 48,
				minWidth: 48,
			},
		},
	},
});

interface ButtonWithIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	size?: 'small' | 'medium' | 'large';
	text?: string;
	border?: boolean;
	rounded?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	children?: ReactNode;
	dim?: boolean;
}

export const ButtonWithIcon = forwardRef<
	HTMLButtonElement,
	ButtonWithIconProps
>(({ size = 'large', dim = false, text, children, ...props }, ref) => {
	return (
		<StyledButtonWithIcon
			{...props}
			dim={dim}
			size={size}
			hasText={Boolean(text)}
			ref={ref}
		>
			{children}
			{Boolean(text) && <span className='button-text'>{text}</span>}
		</StyledButtonWithIcon>
	);
});
