import { FC } from 'react';
import './Spinner.css';
import { Loader, StyledSpinner } from './Spinner.style';

interface ISpinnerProps {
	text?: string;
	size?: 'small' | 'regular';
}

export const Spinner: FC<ISpinnerProps> = ({
	text,
	size = 'regular',
	...props
}) => {
	return (
		<StyledSpinner {...props}>
			<div>
				<Loader size={size} />
			</div>
			{text && <p className='text'>{text}</p>}
		</StyledSpinner>
	);
};
