import {DI} from "../../../../apps/crossword-maker/src/shared/di";
import {ICrosswordRepository} from "./CrosswordRepository.types";

export const CrosswordRepository = (): ICrosswordRepository => {
	const CrosswordApi = DI.resolve("CrosswordApi");

	return {
		async deleteOne(id) {
			return await CrosswordApi.deleteOne(id);
		},

		async getMany(entity) {
			return await CrosswordApi.getMany(entity);
		},

		async generateCrossword(crosswordId) {
			return await CrosswordApi.generateCrossword(crosswordId);
		},

		async exportSvg(id, mode) {
			return await CrosswordApi.exportSvg(id, mode);
		},

		async getCrosswordSolutionWords(ids) {
			return await CrosswordApi.getCrosswordSolutionWords(ids);
		},

		async exportUsedWordsToTxt(id: string) {
			return await CrosswordApi.exportUsedWordsToTxtApi(id);
		}
	};
};
