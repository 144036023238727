import { format, parseISO } from 'date-fns';

export const getDate = (date: Date) => format(date, 'dd/MM/yy');

export const getPreciseDate = (date: string) =>
	format(parseISO(date), 'dd/MM/yy HH:mm');

export const getPreciseVerboseDate = (date: string) =>
	format(parseISO(date), "do 'of' LLLL, yyyy 'at' HH:mm");

export const getApiCurrentDate = (): string => {
	const currentTime = new Date();
	const formattedTime = format(currentTime, "yyyy-MM-dd'T'HH:mm:ss+00:00");
	return formattedTime;
};
