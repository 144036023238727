import { useMemo } from 'react';
import { useUserContext } from '../User/User.hooks';
import { ISelectOption } from 'ui/form';

export const languageLabels: Record<string, string> = {
  dk: 'Danish',
  en: 'English',
  no: 'Norwegian',
  se: 'Swedish',
};

export interface IViewModelReturn {
  languageOptions: ISelectOption[];
}

export const LanguageSwitcherViewModel = (): IViewModelReturn => {
  const { accountLanguages } = useUserContext();

  const languageOptions = useMemo(() => {
    return accountLanguages.map((language) => ({
      value: language,
      label: languageLabels[language],
    }));
  }, [accountLanguages]);

  return {
    languageOptions,
  };
};
