import { ControlledMenu, Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { css, styled } from 'stitches';

const MenuStyles = css({
	'.szh-menu': {
		boxSizing: 'border-box',
		zIndex: 100,
		padding: 0,
		listStyle: 'none',
		userSelect: 'none',
		fontSize: '0.925rem',
		border: '1px solid $grayLight',
		boxShadow: '1px 1px 20px 1px $black20',
		borderRadius: '$large',
		backgroundColor: '$white',
	},

	'.szh-menu__item': {
		color: '$black',
		fontFamily: '$regular',
		padding: '$1 $2',
		whiteSpace: 'nowrap',
		'&.danger': {
			color: '$red',
		},
		'.--hover': {
			color: '$white',
			backgroundColor: '$lightGray',
		},
	},

	'.szh-menu__item--disabled': {
		color: '$black20',
		cursor: 'not-allowed',
	},

	'li[role="presentation"]': {
		// submenu
		color: '$black',
		fontFamily: '$regular',
		padding: 0,
		position: 'relative',
		' > [role="menuitem"]': {
			padding: '$1 $2',
		},
	},

	'li[role="separator"]': {
		margin: '4px 0',
	},

	variants: {
		size: {
			fit: {
				ul: {
					width: 'unset',
				},
			},
			small: {
				ul: {
					width: '200px',
				},
			},
			large: {
				ul: {
					width: '350px',
				},
			},
		},
	},
	defaultVariants: {
		size: 'fit',
	},
});

export const StyledMenu = styled(Menu, MenuStyles);

export const StyledControlledMenu = styled(ControlledMenu, MenuStyles);
