import {ECrosswordType} from "../../../Crossword.types";
import {AppPaths} from "../../../../App/App.constant";
import {generatePath} from "react-router-dom";

export const getCrosswordEditUrl = (type: ECrosswordType, id: string) => {
  const pathMap: Partial<Record<ECrosswordType, string>> = {
    [ECrosswordType.CLUES_IN_SQUARES]: AppPaths.crossword.edit,
    [ECrosswordType.CODEWORD]: AppPaths.crossword.codeword.edit,
    [ECrosswordType.COMBI]: AppPaths.crossword.combi.edit,
    [ECrosswordType.CLASSIC]: AppPaths.crossword.classic.edit,
    [ECrosswordType.MULTI_DIFFICULTY]: AppPaths.crossword.multiDifficulty.edit,
  };

  return generatePath(pathMap[type] || AppPaths.crossword.classic.edit, {
    crosswordId: id
  });
};
