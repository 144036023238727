import {FC, lazy, Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {AppPaths} from "../App/App.constant";
import {useUserContext} from "../User/User.hooks";
import {AppRoutesViewModel} from "./AppRoutes.viewmodel";
import {Page} from "ui/layout";
import {Spinner} from "ui/spinner";

const LoggedOutPage = lazy(() => import("../Auth/Views/LoggedOutPage"));
const CreateCrosswordContainer = lazy(() => import("../Crossword/CreateCrossword/Views/CreateCrosswordPage/CreateCrosswordContainer"));
const Crossword = lazy(() => import("../Crossword/Crossword"));
const CrosswordArchive = lazy(() => import("../CrosswordArchive/CrosswordArchive"));
const CrosswordDashboardPage = lazy(() => import("../CrosswordDashboard/CrosswordDashboard.page"));
const PuzzleSet = lazy(() => import("../PuzzleSet/PuzzleSet"));
const SettingsPage = lazy(() => import("../Settings/SettingsPage"));
const Wordlist = lazy(() => import("../Wordlist/Wordlist"));
const LanguageSwitcherPage = lazy(() => import("../LanguageSwitcher/LanguageSwitcherPage"));
const CreateClassicCrosswordContainer = lazy(() => import("../Crossword/CreateCrossword/Views/CreateCrosswordPage/CreateClassicCrosswordContainer"));
const CISCrosswordEditorContainer = lazy(() => import("../CISCrosswordEditorContainer"));
const ClassicCrosswordEditorContainer = lazy(() => import("../ClassicCrosswordEditorContainer"));
const CreateBulkCrosswordContainer = lazy(() => import("../Crossword/CreateCrossword/Views/CreateCrosswordPage/CreateBulkCrosswordContainer"));
const ViewBulkCrosswordContainer = lazy(() => import("../BulkCrossword/ViewBulkCrosswordContainer"));
const CreateCodewordCrosswordContainer = lazy(() => import("../Crossword/CreateCrossword/Views/CreateCrosswordPage/CreateCodewordCrosswordContainer"));
const CodewordCrosswordEditorContainer = lazy(() => import("../CodewordCrosswordEditorContainer"));
const CreateCombiCrosswordContainer = lazy(() => import("../Crossword/CreateCrossword/Views/CreateCrosswordPage/CreateCombiCrosswordContainer"));
const CombiCrosswordEditorContainer = lazy(() => import("../CombiCrosswordEditorContainer"));
const CreateMultiDifficultyCrosswordContainer = lazy(() => import("../Crossword/CreateCrossword/Views/CreateCrosswordPage/CreateMultiDifficultyCrosswordContainer"));
const MultiDifficultyCrosswordEditorContainer = lazy(() => import("../MultiDifficultyEditorContainer"));

export const AppRoutes: FC = () => {
	const {SidebarLayout, CleanLayout} = AppRoutesViewModel();
	const {currentAccount, currentLanguage, accountLanguages} = useUserContext();

	if (!currentAccount?.id) {
		return null;
	}

	if (!accountLanguages.length) {
		return (
			<Page centerContent>
				<h2>There's no available language for your account</h2>
			</Page>
		);
	}

	return (
		<Suspense fallback={<Spinner/>}>
			<Routes>
				<Route path={AppPaths.logout} element={<LoggedOutPage/>}/>
				{!currentLanguage && accountLanguages.length > 1 && (
					<Route path={AppPaths.languageSwitcher} element={<LanguageSwitcherPage/>}/>
				)}

				{/* Routes to be wrapped in layout with sidebar */}
				<Route element={<SidebarLayout/>}>
					<Route path={AppPaths.crossword.root} element={<CrosswordDashboardPage/>}/>
					<Route path={AppPaths.wordList} element={<Wordlist/>}/>
					<Route path={AppPaths.crossword.archive} element={<CrosswordArchive/>}/>
					<Route path={AppPaths.puzzleSet} element={<PuzzleSet/>}/>
					<Route path={AppPaths.settings} element={<SettingsPage/>}/>
					<Route path={AppPaths.help} element={<span>TODO: Help page</span>}/>
				</Route>

				{/* Routes to be wrapped in layout without sidebar */}
				<Route element={<CleanLayout/>}>
					<Route path={`${AppPaths.crossword.root}`} element={<Crossword/>}>
						<Route path={AppPaths.crossword.create} element={<CreateCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.classic.create} element={<CreateClassicCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.bulk.create} element={<CreateBulkCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.edit} element={<CISCrosswordEditorContainer/>}/>
						<Route path={AppPaths.crossword.classic.edit} element={<ClassicCrosswordEditorContainer/>}/>
						<Route path={AppPaths.crossword.bulk.view} element={<ViewBulkCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.codeword.create} element={<CreateCodewordCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.codeword.edit} element={<CodewordCrosswordEditorContainer/>}/>
						<Route path={AppPaths.crossword.combi.create} element={<CreateCombiCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.combi.edit} element={<CombiCrosswordEditorContainer/>}/>
						<Route path={AppPaths.crossword.multiDifficulty.create} element={<CreateMultiDifficultyCrosswordContainer/>}/>
						<Route path={AppPaths.crossword.multiDifficulty.edit} element={<MultiDifficultyCrosswordEditorContainer/>}/>
					</Route>
				</Route>

				<Route path="*" element={<Navigate to={AppPaths.crossword.root}/>}/>
			</Routes>
		</Suspense>
	);
};
