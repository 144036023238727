import {DI} from "../../../../apps/crossword-maker/src/shared/di";
import {type ReplaceWordDataType} from "../../Repository/WordRepository/WordRepository.types";

export const ReplaceWordUseCase = () => {
	const WordRepository = DI.resolve("WordRepository");

	return {
		async execute(data: ReplaceWordDataType) {
			return await WordRepository.replaceWord(data);
		},
	};
};
