import { styled } from 'stitches';

export const StyledSection = styled('section', {
	marginBottom: '$5',
});

export const Header = styled('div', {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const Title = styled('h3', {
	fontFamily: '$semiBold',
	marginBottom: '$2',
});

export const Content = styled('div', {
	border: '1px solid $black20',
	padding: '$4',
	borderRadius: '$large',
	variants: {
		noFrame: {
			true: {
				border: 'unset',
				padding: 0,
			},
		},
	},
});

export const ActionNode = styled('div', {
	display: 'flex',
	gap: '$3',
});
