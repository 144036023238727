import {asFunction} from "awilix";

import {
	ImportModalViewModel
} from "../../../Domain/Crossword/CreateCrossword/Views/ConfigureLayout/Components/ImportModal/ImportModal.viewmodel";
import {
	StartCleanModalViewModel
} from "../../../Domain/Crossword/CreateCrossword/Views/ConfigureLayout/Components/StartCleanModal/StartCleanModal.viewmodel";
import {
	ConfigureLayoutViewmodel
} from "../../../Domain/Crossword/CreateCrossword/Views/ConfigureLayout/ConfigureLayout.viewmodel";
import {SettingsPageViewmodel} from "../../../Domain/Settings/SettingsPage.viewmodel";

export const DIviewModels = {
	ConfigureLayoutViewmodel: asFunction(ConfigureLayoutViewmodel),
	ImportModalViewModel: asFunction(ImportModalViewModel),
	StartCleanModalViewModel: asFunction(StartCleanModalViewModel),
	SettingsPageViewmodel: asFunction(SettingsPageViewmodel),
};
