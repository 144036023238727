import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { PaginationInfo } from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import {
	IGridPatternApiEntity,
	IGridPatternsRequestData,
} from '../../Api/GridPattern';

export type TGetManyGridPatternUseCase = {
	execute(data: IGridPatternsRequestData): Promise<{
		items: IGridPatternApiEntity[];
		paginationInfo: PaginationInfo;
	}>;
};

export function GetManyGridPatternUseCase(): TGetManyGridPatternUseCase {
	const GridPatternRepository = DI.resolve('GridPatternRepository');

	return {
		async execute(data) {
			return await GridPatternRepository.getMany(data);
		},
	};
}
