import { styled } from "stitches";

export const CenterLayout = styled("div", {
	display: "flex",
	flexDirection: "column",
});

export const GridBlockFooter = styled("div", {
	display: "flex",
	justifyContent: "space-around",
	flexDirection: "column",
	margin: "50px 20px",
});
