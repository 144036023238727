import { FC } from 'react';
import toast, { ToastBar, Toaster, ToasterProps } from 'react-hot-toast';
import { ToastNotificationTemplate } from './ToastNotificationTemplate/ToastNotificationTemplate';

export const ToastNotification: FC<ToasterProps> = (props) => {
	const closeToast = (toastId: string) => {
		toast.dismiss(toastId);
	};

	return (
		<Toaster {...props}>
			{(toast) => (
				<ToastBar toast={toast}>
					{({ message }) => (
						<ToastNotificationTemplate
							type={toast.type}
							message={message}
							onClose={() => closeToast(toast.id)}
						/>
					)}
				</ToastBar>
			)}
		</Toaster>
	);
};
