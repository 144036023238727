import { styled } from 'stitches';
import { CenterLayout } from '../../../../shared/components/CenterLayout/CenterLayout.style';

export const StyledTopBar = styled(CenterLayout, {
	width: '100%',
	height: 80,
	minHeight: 80,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderBottom: '1px solid #ccc',
	gridArea: 'topbar',
	padding: '0 48px',
});

export const TopBarTitle = styled('div', {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-start',
});

export const TopBarIcon = styled('div', {
	width: 48,
	height: 48,
	marginRight: '1rem',
});

export const TopBarHeading = styled('h2', {
	fontSize: '$sectionTitle',
	color: '$black',
	display: 'flex',
	alignItems: 'baseline',
});

export const TopBarStatusTag = styled("div", {
	padding: '2px 6px 3px',
	alignSelf: 'center',
	fontSize: 12,
	fontFamily: '$semiBold',
	letterSpacing: '0.25px',
	color: '$white',
	borderRadius: 6,
	backgroundColor: "#f58b00",
	margin: '4px 0 0 8px',

	variants: {
		status: {
			in_progress: {
				backgroundColor: "#f58b00",
			},
			in_review: {
				backgroundColor: "#007aff",
			},
			completed: {
				backgroundColor: "#00b769",
			},
			generation_started: {},
			generation_failed: {},
			generation_succeeded: {}
		},
	},

	defaultVariants: {
		status: "progress",
	},
});

export const TopBarStatus = styled('span', {
	fontSize: 18,
	color: '$indigo',
	textTransform: 'uppercase',
	fontFamily: '$semiBold',
	marginLeft: '$3',
});

export const Avatar = styled('div', {
	circle: 48,
	backgroundColor: '$greenFoam',
	fontWeight: 500,
	color: '$black',
	overflow: 'hidden',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	userSelect: 'none',
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
});
