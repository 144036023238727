import { ITagEntity } from 'ui/form/TagsInput/TagsInput.types';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { ITagsRequestData } from '../../Api/Tag';

export type TGetManyTagUseCase = {
	execute(data: ITagsRequestData): Promise<ITagEntity[]>;
};

export function GetManyTagUseCase(): TGetManyTagUseCase {
	const TagRepository = DI.resolve('TagRepository');

	return {
		async execute(data: ITagsRequestData) {
			return await TagRepository.getMany(data);
		},
	};
}
