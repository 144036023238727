import { styled } from 'stitches';

export const ButtonsRow = styled('div', {
	marginTop: '$6',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	gap: '$1',
	variants: {
		minimal: {
			true: {
				marginTop: '$2',
			},
		},
	},
});
