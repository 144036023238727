import { IOption, ITagEntity } from './TagsInput.types';

export const createOption = (option: ITagEntity): IOption => {
	const { name, id } = option;

	return {
		label: name,
		value: id,
		name: id,
		id: id,
		__isNew__: false,
	};
};
