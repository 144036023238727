import { ITagEntity } from 'ui/form';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TGetTagsWordUseCase = {
	execute(id: string): Promise<ITagEntity[]>;
};

export const GetTagsWordUseCase = (): TGetTagsWordUseCase => {
	const WordRepository = DI.resolve('WordRepository');

	return {
		async execute(id) {
			const result = await WordRepository.getTags(id);

			return result;
		},
	};
};
