export enum MercureError {
	UNAUTHORIZED_ERROR = 'MERCURE_UNAUTHORIZED_ERROR',
}

export const MERCURE_RETRY_INTERVAL = 5000; // 5s

export enum MercureStatus {
	connected = 'connected',
	disconnected = 'disconnected',
	reconnecting = 'reconnecting',
	error = 'error',
}
