import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TDeleteOneWordClue = {
	execute(id: string): Promise<{ ok: boolean } | undefined>;
};

export const DeleteOneWordClue = (): TDeleteOneWordClue => {
	const WordClueRepository = DI.resolve('WordClueRepository');

	return {
		async execute(id) {
			const result = await WordClueRepository.deleteOne(id);

			return result;
		},
	};
};
