import { database } from '../../../../apps/crossword-maker/src/Database/Database';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { PaginationInfo } from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import {
	ICrosswordApiEntity,
	ICrosswordsApiRequestData,
} from '../../Api/Crossword';

export type TGetManyCrosswordUseCase = {
	execute(data?: ICrosswordsApiRequestData): Promise<{
		items: ICrosswordApiEntity[];
		paginationInfo: PaginationInfo;
	}>;
};

export const GetManyCrosswordUseCase = (): TGetManyCrosswordUseCase => {
	const CrosswordRepository = DI.resolve('CrosswordRepository');

	return {
		async execute(data) {
			const result = await CrosswordRepository.getMany(data);

			if (result) {
				database.crossword.bulkPut(result.items);
			}

			return result;
		},
	};
};
