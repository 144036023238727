import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IClueRepository } from './ClueRepository.types';

export const ClueRepository = (): IClueRepository => {
	const ClueApi = DI.resolve('ClueApi');

	return {
		async update(id, data) {
			return await ClueApi.update(id, data);
		},

		async create(data) {
			return await ClueApi.create(data);
		},

		async getMany(data) {
			return await ClueApi.getMany(data);
		},
	};
};
