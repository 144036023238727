import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IThemeRequestData } from '../../Api/Theme';
import { IThemeRepository } from './ThemeRepository.types';

export const ThemeRepository = (): IThemeRepository => {
	const ThemeApi = DI.resolve('ThemeApi');

	return {
		async getMany(data: IThemeRequestData) {
			return await ThemeApi.getMany(data);
		},
	};
};
