import { Link } from 'react-router-dom';
import { styled } from 'stitches';
import { CenterLayout } from '../../../../shared/components/CenterLayout/CenterLayout.style';

export const Nav = styled('nav', {
	display: 'flex',
	flexDirection: 'column',
});

export const NavLink = styled(Link, {
	color: '#000',
	display: 'flex',
	alignItems: 'center',
	textDecoration: 'none',
	padding: '0 1rem',
	cursor: 'pointer',
	fontSize: '1rem',
	height: 48,
	borderRadius: '$xlarge',
	marginBottom: 4,
	'&:focus': {
		outline: 'none',
	},
	'&:hover': {
		backgroundColor: 'rgba(0,0,0,0.05)',
	},
	variants: {
		active: {
			true: {
				color: '$indigoDark',
				backgroundColor: '$indigo08',
				cursor: 'default',
				fontFamily: '$semiBold',
				'&:hover': {
					backgroundColor: '$indigo08',
				},
			},
		},
	},
});

export const NavLinkContent = styled('div', {
	display: 'flex',
	gap: '$2',
	justifyContent: 'flex-start',
	alignItems: 'center',
});

export const StyledSidebar = styled(CenterLayout, {
	width: '100%',
	height: '100%',
	borderRight: '1px solid #ccc',
	padding: '$3',
	paddingLeft: '$6',
	display: 'flex',
	justifyContent: 'space-between',
	gridArea: 'sidebar',
});

export const SelectContainer = styled('div', {
	marginBottom: '$3',
});
