import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { ICrosswordApiEntity } from './Crossword.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TGenerateCrossword = (id: string) => Promise<ICrosswordApiEntity>;

export const generateCrossword: TGenerateCrossword = async (id) => {
	const language = getCurrentLanguage();

	const response = await apiClient.post<ICrosswordApiEntity>(
		`/rest/${language}/crossword/${id}/generate`,
	);

	return response.data;
};
