import { useAuth0 } from '@auth0/auth0-react';
import { IUserApiEntity } from 'data/Api/User';
import {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { DI } from '../../shared/di';
import { useAuthContext } from '../Auth/Auth.hooks';
import { IUserContext, UserContext } from './User.context';
import { generateUserPermissionLanguages, generateUserPermissions } from './User.utils';
import { AppPaths } from '../App/App.constant';
import { generatePath, useNavigate } from 'react-router-dom';

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
	const GetSelfUserUseCase = DI.resolve('GetSelfUserUseCase');
	const GetManyUsersUseCase = DI.resolve('GetManyUsersUseCase');

	const [users, setUsers] = useState<IUserApiEntity[]>([]);
	const [currentAccount, setCurrentAccount] = useState<IUserApiEntity | null>(
		null,
	);
	const [currentLanguage, setCurrentLanguage] = useState<string>('');

	const { isAuthenticated } = useAuth0();
	const { currentToken } = useAuthContext();
	const navigate = useNavigate();

	const localStorageCurrentLanguage = localStorage.getItem('current_language');

	const languageSwitcherUrl = generatePath(AppPaths.languageSwitcher);

	/**
	 * Get the current user from the API
	 */
	const getSelfUser = useCallback(async () => {
		const user = await GetSelfUserUseCase.execute();
		setCurrentAccount(user ?? null);
	}, []);

	useEffect(() => {
		if (isAuthenticated && currentToken) {
			getSelfUser();
		}
	}, [isAuthenticated, currentToken]);

	const accountPermissions = useMemo(
		() => generateUserPermissions(currentAccount),
		[currentAccount],
	);

	const accountLanguages = useMemo(
		() => generateUserPermissionLanguages(currentAccount),
		[currentAccount],
	);

	const setDefaultCurrentLanguage = useCallback( (language: string) => {
		localStorage.setItem('current_language', `${language}`);
		setCurrentLanguage(language);
	}, [setCurrentLanguage]);

	useEffect(() => {
		if (currentLanguage) return;

		if (!!localStorageCurrentLanguage) {
			setCurrentLanguage(localStorageCurrentLanguage);
		} else if (accountLanguages.length) {
			if (accountLanguages.length > 1) {
				navigate(languageSwitcherUrl);
			} else {
				setDefaultCurrentLanguage(accountLanguages[0]);
			}
		}
	}, [accountLanguages, currentLanguage, localStorageCurrentLanguage]);

	/**
	 * Get all users from the API
	 */
	useEffect(() => {
		const getAllUsers = async () => {
			const users = await GetManyUsersUseCase.execute({
				pageSize: 100,
			});
			setUsers(users);
		};

		if (currentAccount?.id && currentLanguage && !users.length) {
			getAllUsers();
		}
	}, [users, currentAccount, currentLanguage]);

	/**
	 * Create the context value
	 */
	const UserContextValue: IUserContext = useMemo(
		() => ({
			currentAccount,
			accountPermissions,
			accountLanguages,
			currentLanguage,
			setCurrentLanguage: setDefaultCurrentLanguage,
			users,
		}),
		[currentAccount, accountPermissions, users, accountLanguages, currentLanguage],
	);

	return (
		<UserContext.Provider value={UserContextValue}>
			{children}
		</UserContext.Provider>
	);
};
