import {IUpdateGridPatternRequestData} from "data/Api/GridPattern";
import {cloneDeep} from "lodash";
import {useCallback, useContext} from "react";
import {DI} from "../../shared/di";
import {CrosswordContext, ICrosswordContext} from "./Crossword.context";
import {ECrosswordType} from "./Crossword.types";
import {purgeGridPatternData} from "./Crossword.utils";

export const useCrosswordContext = (): ICrosswordContext => {
	return useContext(CrosswordContext);
};

export const useGridPatternUtils = () => {
	const UpdateGridPatternUseCase = DI.resolve('UpdateGridPatternUseCase');
	const CreateGridPatternUseCase = DI.resolve('CreateGridPatternUseCase');

	const { currentCrossword } = useCrosswordContext();

	/**
	 * Get the current grid pattern data, purge it and update it
	 */
	const updateCurrentGridPattern = useCallback(async () => {
		if (!currentCrossword?.gridPatternData) return;

		const clonedGridPatternData = cloneDeep(currentCrossword.gridPatternData);

		const purgedGridPatternData = purgeGridPatternData(
			clonedGridPatternData.data,
		);

		return await UpdateGridPatternUseCase.execute(clonedGridPatternData.id, {
			...clonedGridPatternData,
			data: purgedGridPatternData,
		} as IUpdateGridPatternRequestData);
	}, [currentCrossword]);

	/**
	 * Create a new grid pattern based on current crossword pattern
	 */
	const saveCurrentGridPatternAsNew = useCallback(
		async (
			name: string,
			crosswordType?: ECrosswordType,
			description?: string,
		) => {
			if (!currentCrossword?.gridPatternData) return;

			const clonedGridPatternData = cloneDeep(currentCrossword.gridPatternData);

			const purgedGridPatternData = purgeGridPatternData(
				clonedGridPatternData.data,
			);

			return await CreateGridPatternUseCase.execute({
				name,
				description,
				type: crosswordType ?? ECrosswordType.CLUES_IN_SQUARES,
				data: purgedGridPatternData,
			});
		},
		[currentCrossword],
	);

	return {
		updateCurrentGridPattern,
		saveCurrentGridPatternAsNew,
	};
};
