import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import {
	ICreateGridPatternRequestData,
	IGridPatternApiEntity,
} from '../../Api/GridPattern';

export type TCreateGridPatternUseCase = {
	execute(data: ICreateGridPatternRequestData): Promise<IGridPatternApiEntity>;
};

export function CreateGridPatternUseCase(): TCreateGridPatternUseCase {
	const GridPatternRepository = DI.resolve('GridPatternRepository');

	return {
		async execute(data) {
			return await GridPatternRepository.create(data);
		},
	};
}
