import {Icon} from "@phosphor-icons/react";
import {createContext} from "react";
import {TCrosswordStatus} from "../Crossword/Crossword.types";

export interface ITopbarState {
	Icon?: Icon;
	iconPath?: string;
	title?: string;
	isCampaignMode?: boolean;
	crosswordStatus?: TCrosswordStatus,
}

export interface IAppContextType {
	topBarState: ITopbarState;
	setTopBarState: (state: ITopbarState) => void;
}

export const AppContext = createContext<IAppContextType>({
	topBarState: {
		Icon: undefined,
		iconPath: undefined,
		title: undefined,
		isCampaignMode: false,
		crosswordStatus: 'in_progress',
	},
	setTopBarState: () => {},
});
