import {IDummyPatternRequestData, IGridPatternApiEntity,} from "data/Api/GridPattern";
import {useCallback, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {errorToast, successToast} from "ui/notifications";
import {DI} from "../../../../../../../shared/di";
import {useCrosswordContext} from "../../../../../Crossword.hooks";
import {useNavigate} from "react-router-dom";
import {updateCrossword} from "../../../../../Api/UpdateCrossword.api";
import {ECrosswordType} from "../../../../../Crossword.types";
import {getCrosswordEditUrl} from "../../ConfigureLayout.utils";

export interface IViewModelReturn {
	form: Omit<
		UseFormReturn<IDummyPatternRequestData>,
		"watch" | "getValues" | "setValue"
	>;
	isGettingGridPattern: boolean;
	createButtonHandler: () => Promise<void>;
}

export const StartCleanModalViewModel = (): IViewModelReturn => {
	const GetDummyPatternUseCase = DI.resolve("GetDummyPatternUseCase");
	const navigate = useNavigate();

	const {currentCrossword, setCurrentCrossword} = useCrosswordContext();
	const [isGettingGridPattern, setIsGettingGridPattern] = useState(false);

	const {getValues, ...form} = useForm<IDummyPatternRequestData>({
		mode: "onBlur",
		defaultValues: {
			width: 10,
			height: 10,
			type: currentCrossword?.type === ECrosswordType.COMBI || currentCrossword?.type === ECrosswordType.MULTI_DIFFICULTY
				? ECrosswordType.CLUES_IN_SQUARES
				: currentCrossword?.type,
		},
	});

	const updateCrosswordWithGridPattern = useCallback(
		async (gridPatternData: IGridPatternApiEntity) => {
			if (!currentCrossword?.id) {
				errorToast("Crossword id is missing");
				return;
			}

			const result = await updateCrossword(
				currentCrossword?.id,
				{
					...currentCrossword,
					gridPatternData: {
						...gridPatternData,
					},
				},
			);

			if (result) {
				const {gridPatternData} = result;
				const url = getCrosswordEditUrl(currentCrossword.type, currentCrossword.id)

				successToast(
					`Clean ${gridPatternData.width}x${gridPatternData.height} pattern assigned crossword`,
				);
				setCurrentCrossword(result);

				navigate(url);
			} else {
				errorToast("Failed to assign pattern to crossword");
			}

			setIsGettingGridPattern(() => false);
		},
		[currentCrossword],
	);

	const createButtonHandler = useCallback(async () => {
		setIsGettingGridPattern(true);

		const values = getValues() as IDummyPatternRequestData;

		const result = await GetDummyPatternUseCase.execute({
			...values,
			width: Number(values.width),
			height: Number(values.height),
		});

		if (result?.data.length) {
			updateCrosswordWithGridPattern(result);
		}
	}, []);

	return {
		form,
		isGettingGridPattern,
		createButtonHandler,
	};
};
