import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IWordClueApiEntity } from '../../Api/WordClue';

export type TCreateWithClueWordClueUseCase = {
	execute(
		wordId: string,
		clueText: string,
		difficulty: number,
	): Promise<IWordClueApiEntity | undefined>;
};

export const CreateWithClueWordClueUseCase =
	(): TCreateWithClueWordClueUseCase => {
		const WordClueRepository = DI.resolve('WordClueRepository');

		return {
			async execute(wordId, clueText, difficulty) {
				const result = await WordClueRepository.createWithClue(
					wordId,
					clueText,
					difficulty,
				);

				return result;
			},
		};
	};
