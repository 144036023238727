import { CheckCircle, Warning, WarningCircle, X } from '@phosphor-icons/react';
import { FC, memo, useMemo } from 'react';
import type { ToastNotificationTemplateProps } from '../ToastNotification.types';
import {
	CloseButton,
	Container,
	Icon,
	Message,
} from './ToastNotificationTemplate.styles';

const TOAST_ICON_SIZE = 24;

export const ToastNotificationTemplate: FC<ToastNotificationTemplateProps> =
	memo(({ message, type, onClose }) => {
		const alertStatus = useMemo(() => {
			switch (type) {
				case 'error': {
					return 'error';
				}
				case 'success': {
					return 'success';
				}
				case 'loading': {
					return 'success';
				}
				default:
					return 'warning';
			}
		}, [type]);

		const alertIcon = useMemo(() => {
			switch (type) {
				case 'error': {
					return <WarningCircle weight='fill' size={TOAST_ICON_SIZE} />;
				}
				case 'success': {
					return <CheckCircle weight='fill' size={TOAST_ICON_SIZE} />;
				}
				case 'loading': {
					return <CheckCircle weight='fill' size={TOAST_ICON_SIZE} />;
				}
				default:
					return <Warning weight='fill' size={TOAST_ICON_SIZE} />;
			}
		}, [type]);

		return (
			<Container status={alertStatus}>
				<Icon className='toast-notification-icon'>{alertIcon}</Icon>
				<Message className='toast-notification-message'>{message}</Message>
				<CloseButton>
					<X onClick={onClose} size={16} />
				</CloseButton>
				{/* <AlertIcon /> */}
			</Container>
		);
	});
