import { styled } from 'stitches';

export const Frame = styled('div', {
	display: 'flex',
	justifyContent: 'flex-start',
	flexDirection: 'column',
	padding: '$6',
	borderRadius: '$large',
	border: '1px solid $black20',
	width: 400,
	maxWidth: 400,
	margin: '0 auto',
	variants: {
		isRelative: {
			true: {
				position: 'relative',
			},
		},
		clean: {
			true: {
				border: 'none',
				padding: 0,
			},
		},
	},
});
