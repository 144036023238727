import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IClueApiEntity, IUpdateClueRequestData } from './Clue.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TUpdate = (
	id: string,
	data: IUpdateClueRequestData,
) => Promise<IClueApiEntity>;

export const update: TUpdate = async (id, data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.put<IClueApiEntity>(`/rest/${language}/clue/${id}`, {
		...data,
	});

	return response.data;
};
