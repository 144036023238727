const LOCALSTORAGE_JWT_KEY = 'jwt';

export const setToken = (token: string) => {
	localStorage.setItem(LOCALSTORAGE_JWT_KEY, token);
};

export const retrieveToken = () => {
	return localStorage.getItem(LOCALSTORAGE_JWT_KEY);
};

export const removeToken = () => {
	localStorage.removeItem(LOCALSTORAGE_JWT_KEY);
};
