import { asFunction } from 'awilix';

import {
	ClueRepository,
	CrosswordRepository,
	GridPatternRepository,
	PublicationRepository,
	TagRepository,
	ThemeRepository,
	UserRepository,
	WordClueRepository,
	WordRepository,
} from '.';

export const RepositoryDI = {
	CrosswordRepository: asFunction(CrosswordRepository),
	PublicationRepository: asFunction(PublicationRepository),
	TagRepository: asFunction(TagRepository),
	GridPatternRepository: asFunction(GridPatternRepository),
	ClueRepository: asFunction(ClueRepository),
	WordRepository: asFunction(WordRepository),
	WordClueRepository: asFunction(WordClueRepository),
	UserRepository: asFunction(UserRepository),
	ThemeRepository: asFunction(ThemeRepository),
};
