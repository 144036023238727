import { FC } from 'react';
import { ConfirmProvider } from 'react-confirm-hook';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { globalCss } from 'stitches';
import { ToastNotification } from 'ui/notifications';
import 'unfonts.css';
import { AppRoutes } from '../AppRoutes/AppRoutes';
import { Auth } from '../Auth/Auth';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { CrosswordMercureProvider } from "../Mercure/CrosswordMercure.provider";
import { AppProvider } from './App.provider';

export const App: FC = () => {
	globalCss();

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<CrosswordMercureProvider>
				<ErrorBoundary>
					<ConfirmProvider>
						<BrowserRouter>
							<Auth>
								<AppProvider>
									<AppRoutes />
									<ToastNotification />
								</AppProvider>
							</Auth>
						</BrowserRouter>
					</ConfirmProvider>
				</ErrorBoundary>
			</CrosswordMercureProvider>
		</QueryClientProvider>
	);
};
