import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TDelete = (id: string) => Promise<{ success: boolean }>;

export const deleteOne: TDelete = async (id) => {
	const language = getCurrentLanguage();

	const response = await apiClient.delete<{ success: boolean }>(
		`/rest/${language}/word/${id}`,
	);

	return {
		success: Boolean(response.status < 300),
	};
};
