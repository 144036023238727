import { IGridPatternsRequestData } from 'data/Api/GridPattern';
import { ECrosswordType } from '../../../Crossword.types';

const DEFAULT_SORT_OPTION = 0;

export const sortOptions = [
	{
		value: 'leastused',
		label: 'Least used',
		sort: 'numberOfUses',
		direction: 'asc' as 'desc' | 'asc',
	},
	{
		value: 'mostused',
		label: 'Most used',
		sort: 'numberOfUses',
		direction: 'desc' as 'desc' | 'asc',
	},
];

// NOTE: These defaults might at some point be tied to the publications
export const initialFilterData: IGridPatternsRequestData = {
	filterOwn: false,
	query: '',
	type: ECrosswordType.CLUES_IN_SQUARES,
	minWidth: 1,
	maxWidth: 50,
	minHeight: 1,
	maxHeight: 30,
	image: false,
	sort: sortOptions[DEFAULT_SORT_OPTION].sort,
	direction: sortOptions[DEFAULT_SORT_OPTION].direction,
};
