import { styled } from "stitches";

export const Container = styled("div", {
	display: "flex",
	variants: {
		fillParent: {
			true: {
				width: "100%",
				height: "100%",
				position: "absolute",
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
			},
		},
		centerContent: {
			true: {
				alignItems: "center",
				justifyContent: "center",
			},
		},
		blurred: {
			true: {
				backdropFilter: "blur(3px)",
				zIndex: "$level1",
			},
		},
	},
	defaultVariants: {
		fillParent: false,
		centerContent: false,
	},
});
