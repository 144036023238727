import { keyframes } from '@stitches/react';
import { styled } from 'stitches';

export const StyledSpinner = styled('div', {
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: '$1',
	'.text': {
		marginTop: '$1',
		fontSize: '$body',
		fontWeight: 500,
		color: '$black60',
	},
});

const animationSmall = keyframes({
	'33%': {
		background: '#5856cf',
		boxShadow: '-12px 0 #bcbbec, 12px 0 #bcbbec',
	},
	'66%': {
		background: '#bcbbec',
		boxShadow: '-12px 0 #bcbbec, 12px 0 #5856cf',
	},
	'100%': {
		background: '#bcbbec',
		boxShadow: '-12px 0 #5856cf, 12px 0 #bcbbec',
	},
});

const animationRegular = keyframes({
	'33%': {
		background: '#5856cf',
		boxShadow: '-18px 0 #bcbbec, 18px 0 #bcbbec',
	},
	'66%': {
		background: '#bcbbec',
		boxShadow: '-18px 0 #bcbbec, 18px 0 #5856cf',
	},
	'100%': {
		background: '#bcbbec',
		boxShadow: '-18px 0 #5856cf, 18px 0 #bcbbec',
	},
});

export const Loader = styled('div', {
	borderRadius: '50%',
	display: 'block',
	margin: '0 auto',
	position: 'relative',
	overflow: 'visible',
	background: '#bcbbec',
	boxSizing: 'border-box',
	variants: {
		size: {
			small: {
				size: 8,
				boxShadow: '-12px 0 #5856cf, 12px 0 #bcbbec',
				animation: `${animationSmall} 1s linear infinite`,
			},
			regular: {
				size: 12,
				boxShadow: '-18px 0 #5856cf, 18px 0 #bcbbec',
				animation: `${animationRegular} 1s linear infinite`,
			},
		},
	},
	defaultVariants: {
		size: 'regular',
	},
});
