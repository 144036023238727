import {styled} from "stitches";

export const StyledRadiobutton = styled("label", {
	padding: "$1",
	borderRadius: "$small",
	input: {
		appearance: "none",
		size: "$2",
		position: "relative",
		"&:after": {
			content: "\" \"",
			position: "absolute",
			top: 0,
			left: 0,
			size: "$2",
			backgroundColor: "$white",
			borderRadius: "50%",
			border: "1px solid $black",
		},
		"&:checked:after": {
			backgroundColor: "$indigo",
			top: 2,
			left: 2,
			size: 12,
			border: "2px solid $white",
			$$shadowColor: "$colors$indigo",
			boxShadow: "0 0 0 2px $$shadowColor",
		},
	},
	"&:hover": {
		backgroundColor: "$indigo08",
	},
});

export const HorizontalRadiobuttons = styled("div", {
	marginTop: "$1",
	display: "flex",
	justifyContent: "space-between",
	width: "100%",
	label: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		gap: "$1",
		flex: 1,
	},
});

export const VerticalRadiobuttons = styled("div", {
	marginTop: "$1",
	display: "flex",
	flexDirection: "column",
	label: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "row",
		gap: "$1",
		flex: 1,
	},
});
