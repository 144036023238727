import Dexie, { Table } from "dexie";
import { CROSSWORD_COLUMNS, TCrosswordTable } from "./Crossword/CrosswordTable";
import { DATABASE_NAME, DATABASE_VERSION } from "./Database.constants";
import {
	PUBLICATION_COLUMNS,
	type TPublicationTable,
} from "./Publication/PublicationTable";

class Database extends Dexie {
	crossword!: Table<TCrosswordTable>;
	publication!: Table<TPublicationTable>;

	constructor() {
		super(DATABASE_NAME);

		this.version(DATABASE_VERSION).stores({
			crossword: CROSSWORD_COLUMNS,
			publication: PUBLICATION_COLUMNS,
		});

		this.handleUnhandledRejection();
	}

	handleUnhandledRejection(): void {
		window.addEventListener(
			"unhandledrejection",
			(error: PromiseRejectionEvent) => {
				if (error?.reason?.name === Dexie.errnames.DatabaseClosed) {
					console.error("%Database.ts line:26 = Unhandledrejection of IDDB");

					// TODO for future - we can log out user after Unhandledrejection or make some other logic
					// window.location.replace('/logout');
				}
			},
		);
	}
}

export const database = new Database();
