import {CSSProperties, forwardRef, InputHTMLAttributes} from "react";
import {Label} from "../Label/Label.styles";
import {CheckboxContainer, CheckboxWrapper, StyledCheckbox,} from "./Checkbox.styles";

export interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	checkboxWrapperStyle?: CSSProperties;
	checkboxContainerStyle?: CSSProperties;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxInputProps>(
	({label, checkboxWrapperStyle, checkboxContainerStyle, ...props}, ref) => {
		return (
			<CheckboxWrapper style={checkboxWrapperStyle}>
				<CheckboxContainer style={checkboxContainerStyle}>
					<StyledCheckbox
						{...props}
						id={props.id || props.name}
						ref={ref}
						type="checkbox"
						placeholder="label"
					/>
				</CheckboxContainer>
				{label && (
					<Label htmlFor={props.id || props.name} isDisabled={props.disabled}>
						{label}
					</Label>
				)}
			</CheckboxWrapper>
		);
	},
);
