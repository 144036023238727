import { database } from '../../../../apps/crossword-maker/src/Database/Database';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IPublicationApiEntity } from '../../Api/Publication';

export type TCreatePublicationUseCase = {
	execute(name: string): Promise<IPublicationApiEntity>;
};

export const CreatePublicationUseCase = (): TCreatePublicationUseCase => {
	const PublicationRepository = DI.resolve('PublicationRepository');

	return {
		async execute(name) {
			const result = await PublicationRepository.create(name);

			if (result) {
				database.publication.put(result);
			}

			return result;
		},
	};
};
