import {DI} from "../../../../apps/crossword-maker/src/shared/di";
import {ICrosswordApiEntity} from "../../Api/Crossword";

export type TGenerateCrosswordCase = {
	execute(id: string): Promise<ICrosswordApiEntity>;
};

export const GenerateCrosswordUseCase = (): TGenerateCrosswordCase => {
	const CrosswordRepository = DI.resolve("CrosswordRepository");

	return {
		async execute(id) {
			return await CrosswordRepository.generateCrossword(id);
		},
	};
};
