import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { PaginationResponseData } from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import { IClueApiEntity, IClueApiRequestParams } from './Clue.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TGetMany = (
	data?: IClueApiRequestParams,
) => Promise<IClueApiEntity[]>;

export const getMany: TGetMany = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.get<PaginationResponseData<IClueApiEntity>>(
		`/rest/${language}/clues`,
		{ params: { ...data } },
	);

	return response.data.items;
};
