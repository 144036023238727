import { errorToast } from '../../ui/notifications';

export const getCurrentLanguage = (throwError = true) => {
  const language = localStorage.getItem('current_language');

  if (!language) {
    errorToast('Selected language is not available.');

    if (throwError) {
      throw new Error('Language is not defined');
    }
  }

  return language;
}
