import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TDeleteOne = (id: string) => Promise<{ ok: boolean }>;

export const deleteOne: TDeleteOne = async (id) => {
	const language = getCurrentLanguage();

	const response = await apiClient.delete(`/rest/${language}/word-clue/${id}`);

	return { ok: true, response: response };
};
