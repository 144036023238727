import * as Sentry from "@sentry/react";
import {
  ComponentType,
  FC,
  PropsWithChildren,
  useCallback,
  useState
} from 'react';
import {
  FallbackProps,
} from 'react-error-boundary';
import { ErrorFallback } from './Views/ErrorFallback';
interface ErrorBoundaryProps extends PropsWithChildren {
	FallbackComponent?: ComponentType<FallbackProps>;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
	children,
	FallbackComponent,
}: PropsWithChildren<ErrorBoundaryProps>) => {
	const [key, setKey] = useState(0);

	/**
	 * Is called whenever an error is caught.
	 * This prop can be used to log errors to an error reporting service.
	 */
	const handleOnError = useCallback((error: unknown, componentStack: string | undefined, eventId: string) => {
		// Use your preferred error logging service
		console.error('Caught an error:', error);
    console.error('Component stack:', componentStack);
    console.error('Event ID:', eventId);
	}, []);

	/**
	 * Is called right before the error state is reset.
	 * This function is useful for performing any cleanup or state reset
	 * in your app that should happen before re-rendering after an error.
	 */
	const handleOnReset = useCallback(() => {
		// clearIndexedDB();
		setKey(key + 1);
	}, []);

	return (
    <Sentry.ErrorBoundary
      fallback={(props) => {
          const { error, componentStack, eventId, resetError } = props;
          const fallbackProps = { error, componentStack, eventId, resetErrorBoundary: resetError };
          return FallbackComponent ? <FallbackComponent {...fallbackProps} /> : <ErrorFallback {...fallbackProps} />;
      }}
      onError={handleOnError}
      onReset={handleOnReset}
    >
        {children}
    </Sentry.ErrorBoundary>
    );
};
