import {styled} from "stitches";
import CheckMarkSvg from "./check.svg";

export const CheckboxWrapper = styled("div", {
	display: "flex",
	alignItems: "center",
	gap: "$2",
	zIndex: 0,
	padding: "$2",
	label: {
		fontFamily: "$regular",
	},
});

export const CheckboxContainer = styled("div", {
	position: "relative",
});

export const StyledCheckbox = styled("input", {
	appearance: "none",
	size: 20,
	border: "2px solid $black",
	borderRadius: "$small",
	display: "grid",
	placeContent: "center",
	position: "relative",
	zIndex: "$level1",
	"&:disabled": {
		opacity: 0.2,
		backgroundColor: "$black60",
	},
	"&::before": {
		content: "",
		position: "absolute",
		width: "100%",
		height: "100%",
		transform: "scale(0)",
		background: "$indigo",
		transition: "120ms transform ease-in-out",
		boxShadow: "inset 1em 1em var(--form-control-color)",
	},
	"&:checked": {
		border: "2px solid $indigo",
	},
	"&:checked::before": {
		transform: "scale(1)",
		backgroundImage: `url(${CheckMarkSvg})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
	},
});
