import { styled } from 'stitches';

export const StyledTagUsageCount = styled('span', {
	marginLeft: 5,
	color: 'rgba(10, 10, 10, 0.4)',
});

export const StyledCreateTag = styled('span', {
	fontWeight: 'bold',
});
