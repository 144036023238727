import {styled} from "stitches";

export const StyledGroup = styled("div", {
	display: "flex",
	flexDirection: "column",
	gap: "$1",
	marginBottom: "$3",
	width: "100%",
	variants: {
		noBottomMargin: {
			true: {
				marginBottom: 0,
			},
		},
	},
});
