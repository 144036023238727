import {FC, PropsWithChildren, useMemo, useState} from "react";
import {Tooltip} from "react-tooltip";
import {AppContext, IAppContextType, ITopbarState} from "./App.context";

export const AppProvider: FC<PropsWithChildren> = ({children}) => {
	const [topBarState, setTopBarState] = useState<ITopbarState>({});

	const AppContextValue: IAppContextType = useMemo(
		() => ({
			topBarState,
			setTopBarState,
		}),
		[topBarState],
	);

	return (
		<AppContext.Provider value={AppContextValue}>
			<Tooltip id="tooltip" className="tooltip"/>
			{children}
		</AppContext.Provider>
	);
};
