import {DI} from "../../../../apps/crossword-maker/src/shared/di";
import {IPublicationApiEntity} from "../../Api/Publication";

export type TGetManyPublicationUseCase = {
	execute(): Promise<IPublicationApiEntity[]>;
};

export const GetManyPublicationUseCase = (): TGetManyPublicationUseCase => {
	const PublicationRepository = DI.resolve("PublicationRepository");

	return {
		async execute() {
			return await PublicationRepository.getMany();
		},
	};
};
