import {ICrosswordApiEntity, TCrosswordApiCreateRequestData} from "data/Api/Crossword";
import {getCurrentLanguage} from "utils/src/getCurrentLanguage";
import {apiClient} from "../../../shared/apiClient";

export const updateCrossword = async (id: string, entity: TCrosswordApiCreateRequestData) => {
	const language = getCurrentLanguage();

	const response = await apiClient.put<ICrosswordApiEntity>(
		`/rest/${language}/crossword/${id}`,
		entity,
	);

	return {
		...response.data,
		gridPatternData: {
			...response.data.gridPatternData,
			data: response.data.gridPatternData.data.map((row) => {
				return row.map((cell) => {
					return {
						...cell,
						cellNumber: cell.number,
					};
				});
			}),
		},
	};
};

