import { ITagsInputOption } from 'ui/form';
import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { ITagsRequestData } from '../../Api/Tag';
import { ITagRepository } from './TagRepository.types';

export const TagRepository = (): ITagRepository => {
	const TagApi = DI.resolve('TagApi');

	return {
		async create(data: ITagsInputOption) {
			return await TagApi.create(data);
		},

		async getMany(data: ITagsRequestData) {
			return await TagApi.getMany(data);
		},
	};
};
