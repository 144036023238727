import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IThemeEntity, IThemeRequestData } from '../../Api/Theme';

export type TGetManyThemeUseCase = {
	execute(data: IThemeRequestData): Promise<IThemeEntity[]>;
};

export function GetManyThemeUseCase(): TGetManyThemeUseCase {
	const ThemeRepository = DI.resolve('ThemeRepository');

	return {
		async execute(data: IThemeRequestData) {
			return await ThemeRepository.getMany(data);
		},
	};
}
