import { DI } from '../../../../apps/crossword-maker/src/shared/di';

export type TExportCrosswordSvgUseCase = {
	execute(
		id: string,
		mode: 'web' | 'print' | 'print?showSolution=1',
	): Promise<any | null>;
};

export const ExportCrosswordSvgUseCase = (): TExportCrosswordSvgUseCase => {
	const CrosswordRepository = DI.resolve('CrosswordRepository');

	return {
		async execute(id, mode) {
			const result = await CrosswordRepository.exportSvg(id, mode);

			if (result) {
				// database.publication.bulkPut(result);
			}

			return result;
		},
	};
};
