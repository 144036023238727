import {useAuth0} from "@auth0/auth0-react";
import {FC, useLayoutEffect} from "react";
import {Button} from "ui/button";
import {Page} from "ui/layout";
import {clearIndexedDB} from "../../../Database/Database.utils";
import {useAuth} from "../Auth.hooks";
import {removeToken} from "../Auth.utils";

const LoggedOutPage: FC = () => {
	const {isAuthenticated, loginWithRedirect} = useAuth0();
	const {logout} = useAuth();

	useLayoutEffect(() => {
		if (isAuthenticated) {
			logout();
		} else {
			removeToken();
			clearIndexedDB();
		}
	}, []);

	return (
		<Page centerContent>
			<h1>You're now logged out. Bye 👋</h1>

			<br/>
			<Button mode="primary" onClick={() => loginWithRedirect()}>
				Log in again
			</Button>
		</Page>
	);
};

export default LoggedOutPage;