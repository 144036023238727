export const appEnv = {
	REST_API_URL: import.meta.env.VITE_REST_API_URL || '$ENV{REST_API_URL}',
	MERCURE_PUBLISH_URL:
		import.meta.env.VITE_MERCURE_PUBLISH_URL || '$ENV{MERCURE_PUBLISH_URL}',
	MERCURE_JWT_TOKEN:
		import.meta.env.VITE_MERCURE_JWT_TOKEN || '$ENV{MERCURE_JWT_TOKEN}',
	AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN || '$ENV{AUTH0_DOMAIN}',
	AUTH0_CLIENT_ID:
		import.meta.env.VITE_AUTH0_CLIENT_ID || '$ENV{AUTH0_CLIENT_ID}',
	AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE || '$ENV{AUTH0_AUDIENCE}',
	EMBED_URL_FINN_ORDET:
		import.meta.env.VITE_EMBED_URL_FINN_ORDET || '$ENV{EMBED_URL_FINN_ORDET}',
	EMBED_URL_CROSSWORD:
		import.meta.env.VITE_EMBED_URL_CROSSWORD || '$ENV{EMBED_URL_CROSSWORD}',
	EMBED_URL_EXTENSIONS:
		import.meta.env.VITE_EMBED_URL_EXTENSIONS || '$ENV{EMBED_URL_EXTENSIONS}',
	CROSSWORD_GENERATION_TIMEOUT_SECONDS:
		import.meta.env.VITE_CROSSWORD_GENERATION_TIMEOUT_SECONDS ||
		'$ENV{CROSSWORD_GENERATION_TIMEOUT_SECONDS}',
};
