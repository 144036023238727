import { styled } from 'stitches';

export const Layout = styled('div', {
	display: 'grid',
	flexDirection: 'column',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	gridTemplateColumns: '1fr',
	gridTemplateRows: '80px 1fr',
	gridTemplateAreas: `
    "topbar"
    "route-outlet"`,
});

export const LayoutWithSidebar = styled('div', {
	height: '100%',
	width: '100%',
	display: 'grid',
	gridTemplateColumns: '320px 1fr',
	gridTemplateRows: '80px 1fr',
	gridTemplateAreas: `
    "topbar topbar"
    "sidebar route-outlet"`,
});

export const RouteOutlet = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	marginTop: 'auto',
	marginBottom: 'auto',
	height: '100%',
	gridArea: 'route-outlet',
	overflow: 'auto',
});
