import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IWordApiCreatePayload, IWordApiEntity } from '../../Api/Word';

export type TCreateWordUseCase = {
	execute(data: IWordApiCreatePayload): Promise<IWordApiEntity>;
};

export const CreateWordUseCase = (): TCreateWordUseCase => {
	const WordRepository = DI.resolve('WordRepository');

	return {
		async execute(data) {
			const result = await WordRepository.create(data);

			return result;
		},
	};
};
