import toast, { ToastOptions } from 'react-hot-toast';

const TOAST_DURATION = 3 * 1000;

const coreToastOptions: ToastOptions = {
	duration: TOAST_DURATION,
	position: 'top-center',
	icon: '',
	style: {
		padding: 0,
		maxWidth: 560,
	},
	className: '',
};

export const errorToast = (message: string, options: ToastOptions = {}) => {
	toast.error(message, {
		...coreToastOptions,
		duration: TOAST_DURATION * 2,
		...options,
	});
};

export const successToast = (message: string, options: ToastOptions = {}) => {
	toast.success(message, {
		...coreToastOptions,
		...options,
	});
};

export const clearToasts = () => {
	toast.dismiss();
};
