import { errorToast } from 'ui/notifications';
import { database } from './Database';

export const clearIndexedDB = async () =>
	await database
		.delete()
		.then(async () => await database.open())
		.catch((err) => {
			console.error(err);
			errorToast(err.message);
		});
