import {asFunction} from "awilix";

import {UpdateClueUseCase} from "./Clue";
import {
	DeleteOneCrosswordUseCase,
	ExportCrosswordSvgUseCase,
	GenerateCrosswordUseCase,
	GetCrosswordSolutionWordsUseCase,
	GetManyCrosswordUseCase,
} from "./Crossword";
import {
	CreateGridPatternUseCase,
	GetDummyPatternUseCase,
	GetManyGridPatternUseCase,
	ImportFileGridPatternUseCase,
	UpdateGridPatternUseCase,
} from "./GridPattern";
import {DeleteGridPatternUseCase} from "./GridPattern/DeleteGridPattern.useCase";
import {CreatePublicationUseCase, GetManyPublicationUseCase,} from "./Publication";
import {CreateTagUseCase, GetManyTagUseCase} from "./Tag";
import {GetManyThemeUseCase} from "./Theme/GetManyTheme.useCase";
import {GetManyUsersUseCase, GetSelfUserUseCase} from "./User";
import {
	AddTagsWordUseCase,
	CreateWordUseCase,
	DeleteWordUseCase,
	GetManyWordUseCase,
	GetTagsWordUseCase,
	UpdateWordUseCase,
} from "./Word";
import {DeleteOneWordClue, UpdateWordClueUseCase} from "./WordClue";
import {CreateWithClueWordClueUseCase} from "./WordClue/CreateWithClueWordClue.useCase";
import {ReplaceWordUseCase} from "./Word/ReplaceWord.useCase";
import {ExportUsedWordsCrosswordToTxtUseCase} from "./Crossword/ExportUsedWordsToTxt.useCase";

export const UseCaseDI = {
	GetManyPublicationUseCase: asFunction(GetManyPublicationUseCase),
	CreatePublicationUseCase: asFunction(CreatePublicationUseCase),

	GetManyCrosswordUseCase: asFunction(GetManyCrosswordUseCase),
	DeleteOneCrosswordUseCase: asFunction(DeleteOneCrosswordUseCase),
	GenerateCrosswordUseCase: asFunction(GenerateCrosswordUseCase),
	ExportCrosswordSvgUseCase: asFunction(ExportCrosswordSvgUseCase),
	ExportCrosswordUsedWordsToTxtUseCase: asFunction(ExportUsedWordsCrosswordToTxtUseCase),
	GetCrosswordSolutionWordsUseCase: asFunction(
		GetCrosswordSolutionWordsUseCase,
	),

	GetManyGridPatternUseCase: asFunction(GetManyGridPatternUseCase),
	ImportFileGridPatternUseCase: asFunction(ImportFileGridPatternUseCase),
	GetDummyPatternUseCase: asFunction(GetDummyPatternUseCase),
	UpdateGridPatternUseCase: asFunction(UpdateGridPatternUseCase),
	DeleteGridPatternUseCase: asFunction(DeleteGridPatternUseCase),
	CreateGridPatternUseCase: asFunction(CreateGridPatternUseCase),

	CreateTagUseCase: asFunction(CreateTagUseCase),
	GetManyTagUseCase: asFunction(GetManyTagUseCase),

	GetManyWordUseCase: asFunction(GetManyWordUseCase),
	UpdateWordUseCase: asFunction(UpdateWordUseCase),
	CreateWordUseCase: asFunction(CreateWordUseCase),
	DeleteWordUseCase: asFunction(DeleteWordUseCase),
	ReplaceWordUseCase: asFunction(ReplaceWordUseCase),
	AddTagsWordUseCase: asFunction(AddTagsWordUseCase),
	GetTagsWordUseCase: asFunction(GetTagsWordUseCase),

	UpdateClueUseCase: asFunction(UpdateClueUseCase),

	UpdateWordClueUseCase: asFunction(UpdateWordClueUseCase),
	DeleteOneWordClue: asFunction(DeleteOneWordClue),
	CreateWithClueWordClueUseCase: asFunction(CreateWithClueWordClueUseCase),

	GetSelfUserUseCase: asFunction(GetSelfUserUseCase),
	GetManyUsersUseCase: asFunction(GetManyUsersUseCase),

	GetManyThemeUseCase: asFunction(GetManyThemeUseCase),
};
