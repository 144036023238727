type TFlags = 'mercure' | 'auth' | 'utils' | 'all';
type TModes = 'log' | 'trace';

declare const window: {
	logFlag?: TFlags;
} & Window;

const css =
	'background: #fff; color: #000; padding: 2px 4px; border-radius: 4px;';

/**
 * @description
 * @param {string} [message]
 */
export const flagLogger = (
	flag: TFlags,
	entries: unknown[],
	mode: TModes = 'log',
) => {
	const currentFlag: TFlags | undefined = window.logFlag ?? undefined;

	if (currentFlag === 'all' || flag === currentFlag) {
		console[mode](`%c🚩 ${flag} ▷`, css, ...entries);
	}
};
