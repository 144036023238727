import {ImportGridPatternRequestData} from "data/Api/GridPattern";
import {useCallback, useEffect, useState} from "react";
import {UseFormReturn, useForm} from "react-hook-form";
import {generatePath, useNavigate} from "react-router-dom";
import {errorToast} from "ui/notifications";
import {DI} from "../../../../../../../shared/di";
import {AppPaths} from "../../../../../../App/App.constant";
import {useCrosswordContext} from "../../../../../Crossword.hooks";
import {updateCrossword} from "../../../../../Api/UpdateCrossword.api";

export interface IViewModelReturn {
	form: Omit<
		UseFormReturn<ImportGridPatternRequestData>,
		'watch' | 'getValues' | 'setValue'
	>;
	importButtonHandler: () => void;
	isImporting: boolean;
}

export const ImportModalViewModel = (): IViewModelReturn => {
	const ImportFileGridPatternUseCase = DI.resolve(
		'ImportFileGridPatternUseCase',
	);

	const navigate = useNavigate();
	const { currentCrossword } = useCrosswordContext();
	const { getValues, setValue, watch, ...form } =
		useForm<ImportGridPatternRequestData>({
			mode: 'onBlur',
		});

	const [isImporting, setIsImporting] = useState(false);

	const importButtonHandler = useCallback(async () => {
		if (!currentCrossword?.id) {
			errorToast('Crossword id is missing');
			return;
		}

		setIsImporting(true);

		const values = getValues() as ImportGridPatternRequestData;
		const result = await ImportFileGridPatternUseCase.execute(values);

		if (result.id) {
			const crossword = await updateCrossword(
				currentCrossword.id,
				{
					...currentCrossword,
					gridPatternData: result,
				},
			);

			if (crossword?.id) {
				const url = generatePath(AppPaths.crossword.edit, {
					crosswordId: currentCrossword.id,
				});
				navigate(url);
			}
		} else {
			setIsImporting(false);
		}
	}, [currentCrossword]);

	useEffect(() => {
		const subscription = watch((value) => {
			// if no name is set, set the name to the file name when a file is added to the filedrop
			if (value.files?.length && !value.name?.length) {
				setValue('name', value.files[0].name);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	return {
		form,
		importButtonHandler,
		isImporting,
	};
};
