import {MagnifyingGlass} from "@phosphor-icons/react";
import {CSS} from "@stitches/react";
import {FC, ForwardedRef, forwardRef, InputHTMLAttributes} from "react";
import {Spinner} from "../../spinner";
import {IconContainer, InputWrapper, StyledInput} from "./Input.styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
	ref?: ForwardedRef<HTMLInputElement>;
	css?: CSS;
	isSearch?: boolean;
	isSearching?: boolean;
	inputWrapperClassName?: string;
}

export const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>(
	({isSearch, disabled, isSearching, inputWrapperClassName, ...props}, ref) => {
		return (
			<InputWrapper className={inputWrapperClassName}>
				<StyledInput
					ref={ref}
					{...props}
					autoComplete="off"
					isSearch={isSearch}
					disabled={disabled}
				/>
				{isSearch && (
					<IconContainer isSearching={isSearching}>
						{isSearching ? <Spinner size="small"/> : <MagnifyingGlass/>}
					</IconContainer>
				)}
			</InputWrapper>
		);
	},
);
