import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import {
	IGridPatternApiEntity,
	IUpdateGridPatternRequestData,
} from '../../Api/GridPattern';

export type TUpdateGridPatternUseCase = {
	execute(
		id: string,
		data: IUpdateGridPatternRequestData,
	): Promise<IGridPatternApiEntity>;
};

export function UpdateGridPatternUseCase(): TUpdateGridPatternUseCase {
	const GridPatternRepository = DI.resolve('GridPatternRepository');

	return {
		async execute(id, data) {
			return await GridPatternRepository.update(id, data);
		},
	};
}
