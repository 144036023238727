import { styled } from 'stitches';

export const Container = styled('div', {
	position: 'relative',
	display: 'flex',
	flexDirection: 'row',
	gap: '$3',
	alignItems: 'stretch',
	borderRadius: '$toastRadius',
	padding: '$2',
	width: '100%',
	border: '1px solid $black20',
	variants: {
		status: {
			error: {
				backgroundColor: '$red60',
				color: '$toastError',
			},
			success: {
				backgroundColor: '$green60',
				color: '$toastSuccess',
			},
			warning: {
				backgroundColor: '$yellow',
				color: '$toastWarning',
			},
		},
	},
});

export const Icon = styled('div', {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
export const Message = styled('div', {
	textAlign: 'left',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	padding: 0,
});
export const CloseButton = styled('div', {
	svg: {
		cursor: 'pointer',
		color: '$black60',
		'&:hover': {
			color: '$black',
		},
	},
});
