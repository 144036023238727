import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IUserApiEntity, IUserApiRequestParams } from '../../Api/User';

export type TGetManyUsersUseCase = {
	execute(data: IUserApiRequestParams): Promise<IUserApiEntity[]>;
};

export const GetManyUsersUseCase = (): TGetManyUsersUseCase => {
	const UserRepository = DI.resolve('UserRepository');

	return {
		async execute(data) {
			const result = await UserRepository.getMany(data);

			return result;
		},
	};
};
