import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import {
	IWordClueApiEntity,
	IWordClueApiUpdateRequestData,
} from '../../Api/WordClue';

export type TUpdateWordClueUseCase = {
	execute(
		id: string,
		data: IWordClueApiUpdateRequestData,
	): Promise<IWordClueApiEntity | undefined>;
};

export const UpdateWordClueUseCase = (): TUpdateWordClueUseCase => {
	const WordClueRepository = DI.resolve('WordClueRepository');

	return {
		async execute(id, data) {
			return await WordClueRepository.update(id, data);
		},
	};
};
