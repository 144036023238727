import {IPublicationApiEntity} from "data/Api/Publication";
import {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {successToast} from "ui/notifications";
import {DI} from "../../shared/di";
import {useAppContext} from "../App/App.hooks";
import {useUserContext} from "../User/User.hooks";

type TPublicationOption = Pick<IPublicationApiEntity, "name" | "id">;

export interface IViewModelReturn {
	publicationOptions?: TPublicationOption[];
	createPublication: (name: string) => Promise<void>;
	publicationsLoading: boolean;
}

export const SettingsPageViewmodel = (): IViewModelReturn => {
	const CreatePublicationUseCase = DI.resolve("CreatePublicationUseCase");
	const GetManyPublicationUseCase = DI.resolve("GetManyPublicationUseCase");
	const [publicationOptions, setPublicationOptions] = useState<TPublicationOption[]>([]);
	const {currentLanguage} = useUserContext();
	const [publicationsLoading, setPublicationsLoading] = useState(true);

	const {setTopBarState} = useAppContext();

	useLayoutEffect(() => {
		setTopBarState({
			title: "Settings",
		});
	}, []);

	/**
	 * Publications
	 */

	const getPublications = useCallback(async () => {
		const result = await GetManyPublicationUseCase.execute();

		setPublicationOptions(() => result);
		setPublicationsLoading(() => false);
	}, []);

	useEffect(() => {
		getPublications();

		return () => {
			setPublicationsLoading(() => true);
		}
	}, [getPublications, currentLanguage]);

	const createPublication = useCallback(async (name: string) => {
		const result = await CreatePublicationUseCase.execute(name);

		if (result.id) {
			successToast(`Created publication ${result.name}`);
		}
	}, []);

	return {
		publicationOptions,
		createPublication,
		publicationsLoading,
	};
};
