import {styled} from "stitches";

export const InputWrapper = styled("div", {
	position: "relative",
	width: "100%",
});

export const StyledInput = styled("input", {
	color: "$black",
	width: "100%",
	fontFamily: "$regular",
	fontSize: "$body",
	borderRadius: "$large",
	padding: "0 $2",
	border: "solid 1px rgba(0, 0, 0, 0.12)",
	height: 48,
	maxHeight: 48,
	"&:disabled": {
		backgroundColor: "$black04",
	},
	"&:focus": {
		boxShadow: "inset 0px 0px 0px 2px $colors$black",
	},
	variants: {
		isSearch: {
			true: {
				paddingRight: "$6",
			},
		},
	},
});

export const IconContainer = styled("div", {
	position: "absolute",
	top: 0,
	bottom: 0,
	right: 0,
	width: "$6",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	pointerEvents: "none",
	variants: {
		isSearching: {
			true: {
				width: "$8",
			},
		},
	},
});
