import {useAuth0} from "@auth0/auth0-react";
import {FC, PropsWithChildren, useEffect, useLayoutEffect, useMemo, useState,} from "react";
import {Navigate, Route, Routes, useSearchParams} from "react-router-dom";
import {Button} from "ui/button";
import {Page} from "ui/layout";
import {Spinner} from "ui/spinner";
import {AppPaths} from "../App/App.constant";
import {AuthContext, IAuthContext} from "./Auth.context";
import {useAuth} from "./Auth.hooks";
import {removeToken} from "./Auth.utils";
import LoggedOutPage from "./Views/LoggedOutPage";

export const AuthProvider: FC<PropsWithChildren> = ({children}) => {
	const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0();
	const {getToken} = useAuth();
	const [searchParams] = useSearchParams();

	const error = searchParams.get("error");
	const description = searchParams.get("error_description");

	const {pathname} = location;

	const [currentToken, setCurrentToken] = useState<string | null>(null);

	// remove token on initial load to force using valid token fetched from auth0
	useLayoutEffect(() => {
		removeToken();
	}, []);

	useEffect(() => {
		const tokenRetriever = async () => {
			const token = await getToken();

			setCurrentToken(token);
		};

		if (isAuthenticated && !isLoading) {
			tokenRetriever();
		}
	}, [isAuthenticated, isLoading]);

	if (
		!isLoading &&
		!isAuthenticated &&
		!error &&
		pathname !== AppPaths.logout
	) {
		loginWithRedirect();
	}

	const AuthContextValue: IAuthContext = useMemo(
		() => ({
			currentToken,
		}),
		[currentToken],
	);

	if (isLoading || (!currentToken && pathname !== AppPaths.logout)) {
		return (
			<Page centerContent>
				<Spinner text="Authenticating..."/>
			</Page>
		);
	}

	if (error) {
		return (
			<Page centerContent>
				<h1>{error}</h1>
				{description && (
					<>
						<br/>
						<p>{description}</p>
					</>
				)}
				<br/>
				<Button mode="primary" onClick={() => loginWithRedirect()}>
					Try again
				</Button>
			</Page>
		);
	}

	if (!isAuthenticated && !isLoading) {
		return (
			<Routes>
				<Route path={AppPaths.logout} element={<LoggedOutPage/>}/>
				<Route path="*" element={<Navigate to={AppPaths.root}/>}/>
			</Routes>
		);
	}

	return (
		<AuthContext.Provider value={AuthContextValue}>
			{children}
		</AuthContext.Provider>
	);
};
