import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import {
	IDummyPatternRequestData,
	IGridPatternApiEntity,
} from '../../Api/GridPattern';

export type TGetDummyPatternUseCase = {
	execute(data: IDummyPatternRequestData): Promise<IGridPatternApiEntity>;
};

export function GetDummyPatternUseCase(): TGetDummyPatternUseCase {
	const GridPatternRepository = DI.resolve('GridPatternRepository');

	return {
		async execute(data) {
			return await GridPatternRepository.getDummyPattern(data);
		},
	};
}
