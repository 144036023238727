import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import { App } from './Domain/App/App';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://f879fbca4dc97c473e09d73139486f9b@o4508382028300288.ingest.de.sentry.io/4508398879113296",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/kryssordmakeren\.kryssord\.no/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);