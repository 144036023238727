import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { PaginationResponseData } from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import { IThemeEntity, IThemeRequestData } from './Theme.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TgetMany = (data: IThemeRequestData) => Promise<IThemeEntity[]>;

export const getMany: TgetMany = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.get<PaginationResponseData<IThemeEntity>>(
		`/rest/${language}/themes`,
		{ params: { ...data } },
	);

	return response.data.items;
};
