import { FC, PropsWithChildren } from 'react';
import { styled } from 'stitches';

const StyledItem = styled('li', {
	fontFamily: '$medium !important',
	fontSize: '$sectionTitle',
	marginTop: '$3',
	marginBottom: '$3',
	'[role="group"]': {
		width: '100%',
	},
});

export const MenuItemHeader: FC<PropsWithChildren> = ({ children }) => {
	return (
		<StyledItem role='menuitem' tabIndex={-1} className='szh-menu__item'>
			{children}
		</StyledItem>
	);
};
