import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IUserRepository } from './UserRepository.types';

export const UserRepository = (): IUserRepository => {
	const UserApi = DI.resolve('UserApi');

	return {
		async getMany(data) {
			return await UserApi.getMany(data);
		},

		async getSelf() {
			return await UserApi.getSelf();
		},
	};
};
