import { DI } from '../../../../apps/crossword-maker/src/shared/di';
import { IGridPatternApiEntity } from '../../Api/GridPattern';

export type TDeleteGridPatternUseCase = {
	execute(pattern: IGridPatternApiEntity): Promise<IGridPatternApiEntity>;
};

export function DeleteGridPatternUseCase(): TDeleteGridPatternUseCase {
	const GridPatternRepository = DI.resolve('GridPatternRepository');

	return {
		async execute(pattern) {
			return await GridPatternRepository.deleteOne(pattern);
		},
	};
}
