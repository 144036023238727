import { FC, forwardRef } from 'react';
import { StyledRadiobutton } from './Radiobutton.styles';

interface IRadiobuttonProps {
	id: string;
	name: string;
	value: string;
	label: string;
	defaultChecked?: boolean;
}

export const RadioButton: FC<IRadiobuttonProps> = forwardRef<
	HTMLInputElement,
	IRadiobuttonProps
>(({ label, ...props }, ref) => {
	return (
		<StyledRadiobutton>
			<input type='radio' {...props} ref={ref} />
			{label}
		</StyledRadiobutton>
	);
});
