import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IClueApiEntity, ICreateCluePayload } from './Clue.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TCreate = (data: ICreateCluePayload) => Promise<IClueApiEntity>;

export const create: TCreate = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.post<IClueApiEntity>(`/rest/${language}/clue`, {
		...data,
	});

	return response.data;
};
