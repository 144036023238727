import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigateToPath = () => {
	const navigate = useNavigate();

	const navigateToPath = useCallback((path: string) => {
		navigate(path);
	}, []);

	return {
		navigateToPath,
	};
};
