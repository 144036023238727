import { styled } from 'stitches';

export const List = styled('ul', {
	margin: 0,
	padding: 0,
	listStyle: 'none',
});

export const ListItem = styled('li', {
	color: '$red',
	fontSize: '$bodySmall',
	'&:not(:last-child)': {
		marginBottom: 4,
	},
});
