import { IUserApiEntity } from 'data/Api/User';
import { createContext } from 'react';
import { IUserPermissions } from './User.types';

export interface IUserContext {
	currentAccount: IUserApiEntity | null;
	accountPermissions: IUserPermissions;
	accountLanguages: string[];
	currentLanguage: string;
	setCurrentLanguage: (language: string) => void;
	users: IUserApiEntity[];
}

export const UserContext = createContext<IUserContext>({
	currentAccount: null,
	accountPermissions: {},
	accountLanguages: [],
	currentLanguage: '',
	setCurrentLanguage: () => {},
	users: [],
});
