import debounce from 'lodash/debounce';
import { FC, forwardRef, useCallback, useState } from 'react';
import { ActionMeta } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { theme } from 'stitches';
import {
	reactSelectSharedControlStyles,
	reactSelectSharedMenuStyles,
} from '../Select/Select.styles';
import { StyledTagUsageCount } from './TagsInput.styles';
import { IOption, ITagEntity } from './TagsInput.types';
import { createOption } from './TagsInput.utils';

interface TagsInputProps {
	loadOptionsApi: (inputValue: string) => Promise<ITagEntity[]>;
	createTagApi?: (option: IOption) => Promise<any>;
	handleSelectedOption: (options: IOption[]) => void;
	placeholder?: string;
	defaultValue?: IOption[];
	customValue?: IOption[];
}

export const TagsInput: FC<TagsInputProps> = forwardRef(
	(
		{
			loadOptionsApi,
			createTagApi,
			handleSelectedOption,
			placeholder,
			defaultValue,
			customValue,
		},
		ref,
	) => {
		const [inputValue, setInputValue] = useState<string>('');

		const loadOptions = useCallback(
			debounce((inputText, callback) => {
				loadOptionsApi(inputText).then((options) =>
					callback(options.map((option) => createOption(option))),
				);
			}, 300),
			[],
		);

		const handleBlur = () => {
			setInputValue('');
		};

		const handleInputChange = (inputValue: string) => {
			setInputValue(inputValue);
		};

		const getOptionLabel = useCallback((option: any) => {
			const { label, __isNew__, crosswordsCount } = option;

			if (__isNew__) {
				return <b>{label}</b>;
			}
			return (
				<>
					{label}
					{crosswordsCount > 0 && (
						<StyledTagUsageCount>({crosswordsCount})</StyledTagUsageCount>
					)}
				</>
			);
		}, []);

		const handleChange = async (
			selectedValues: IOption[],
			actionMeta: ActionMeta<IOption>,
		) => {
			if (
				typeof createTagApi === 'function' &&
				actionMeta.action === 'create-option'
			) {
				createTagApi(actionMeta.option);
			} else {
				handleSelectedOption(selectedValues);
			}
		};

		return (
			<AsyncCreatableSelect
				ref={() => ref}
				value={customValue}
				loadOptions={loadOptions}
				defaultValue={defaultValue}
				allowCreateWhileLoading={true}
				createOptionPosition='first'
				isValidNewOption={(inputValue, _value, options) => {
					if (!inputValue.length || !createTagApi) return false;

					const opts = options as IOption[];
					const hasOption = Boolean(
						opts.find((option) => option.label === inputValue),
					);

					return !hasOption;
				}}
				isMulti={true}
				placeholder={placeholder}
				isClearable={false}
				onBlur={handleBlur}
				menuPortalTarget={document.body}
				onInputChange={handleInputChange}
				getOptionLabel={getOptionLabel as any}
				components={{
					DropdownIndicator: null,
				}}
				onChange={handleChange as any}
				loadingMessage={() => 'Loading matching tags'}
				styles={{
					menuPortal: (baseStyles) => ({
						...baseStyles,
						zIndex: 9999,
					}),
					control: (baseStyles, { isFocused }) => ({
						...baseStyles,
						...reactSelectSharedControlStyles,
						borderWidth: isFocused ? 2 : 1,
					}),
					menu: (baseStyles) => ({
						...baseStyles,
						...reactSelectSharedMenuStyles,
						marginTop: 1,
						display: inputValue.length ? 'block' : 'none',
					}),
					loadingMessage: (baseStyles) => ({
						...baseStyles,
						fontSize: theme.fontSizes.bodySmall.toString(),
						color: theme.colors.black.toString(),
					}),
					noOptionsMessage: (baseStyles) => ({
						...baseStyles,
						display: !inputValue.length ? 'none' : 'block',
					}),
					multiValueLabel: (baseStyles) => ({
						...baseStyles,
						paddingLeft: theme.space[1].toString(),
						color: theme.colors.black.toString(),
						fontSize: 12,
					}),
					multiValue: (baseStyles) => ({
						...baseStyles,
						backgroundColor: 'transparent',
						border: `1px solid ${theme.colors.black20.toString()}`,
						fontSize: theme.fontSizes.bodySmall.toString(),
						borderRadius: theme.radii.small.toString(),
						height: 28,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: 8,
						position: 'relative',
					}),
					multiValueRemove: (baseStyles) => ({
						...baseStyles,
						width: 16,
						height: 16,
						opacity: 1,
						color: '#0a0a0a',
						padding: 0,
						marginLeft: 4,
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: theme.colors.indigo.toString(),
							color: theme.colors.white.toString(),
						},
					}),
					option: (baseStyles, { isSelected, isFocused }) => ({
						...baseStyles,
						backgroundColor: isSelected
							? theme.colors.indigoLight.toString()
							: isFocused
							? theme.colors.grayLight.toString()
							: theme.colors.white.toString(),
						color: theme.colors.black.toString(),
						height: 48,
						display: 'flex',
						alignItems: 'center',
						':hover': {
							backgroundColor: isSelected
								? theme.colors.indigoLight.toString()
								: theme.colors.grayLight.toString(),
						},
					}),
				}}
			/>
		);
	},
);
