export const useLocalStorage = () => {
	const setItem = (name: string, data: string) => {
		return localStorage.setItem(name, data);
	};
	const removeItem = (name: string) => {
		return localStorage.removeItem(name);
	};
	const getItem = (name: string) => {
		const localData = localStorage.getItem(name);
		return localData ? JSON.parse(localData) : null;
	};

	return { setItem, removeItem, getItem };
};
