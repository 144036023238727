import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button } from 'ui/button';
import { ButtonsRow } from 'ui/form';
import {
	Content,
	Face,
	FaceContainer,
	StyledErrorFallback,
	Title,
} from './ErrorFallback.style';

export const ErrorFallback: FC<FallbackProps> = ({
	error,
	resetErrorBoundary,
}) => {
	return (
		<StyledErrorFallback>
			<FaceContainer>
				<Face>
					<div className='shadow' />
					<div className='eye' />
					<div className='eye right' />
					<div className='mouth' />
				</Face>
			</FaceContainer>
			<Content>
				<Title>Ooops... Something went wrong 😱</Title>

				<pre>{error.message}</pre>

				<ButtonsRow>
					<Button mode='primary' onClick={resetErrorBoundary}>
						Try again
					</Button>
				</ButtonsRow>
			</Content>
		</StyledErrorFallback>
	);
};
