import {TCreate, TDummyPattern, TGetMany, TImportFile, TUpdate} from ".";
import {ECrosswordType} from "../../../../apps/crossword-maker/src/Domain/Crossword/Crossword.types";
import {IPaginationApi} from "../../../../apps/crossword-maker/src/shared/types/RestApi.types";

export interface IGridPatternApiSource {
	getMany: TGetMany;
	importFile: TImportFile;
	getDummyPattern: TDummyPattern;
	update: TUpdate;
	create: TCreate;
}

export enum CellType {
	OPEN = "O",
	OPEN_SINGLE = "OSC",
	OPEN_DOUBLE = "ODC",
	SINGLE = "SC",
	DOUBLE = "DC",
	EMPTY = "E",
	IMAGE = "I",
	WORD_STREET = "WS",
	BLOCKED = "B",
	CURSOR = "C",
	REVEALED = "R",
}

export type TClueDirection = "V" | "H";

export interface ClueType {
	direction: TClueDirection;
	x: number;
	y: number;
	clue: string;
}

export enum CellArrowDirection {
	UP = "UP",
	UP_RIGHT = "UP_RIGHT",
	RIGHT = "RIGHT",
	LEFT = "LEFT",
	RIGHT_DOWN = "RIGHT_DOWN",
	RIGHT_INLINE = "RIGHT_INLINE",
	DOWN = "DOWN",
	DOWN_RIGHT = "DOWN_RIGHT",
	DOWN_INLINE = "DOWN_INLINE",
	LEFT_DOWN = "LEFT_DOWN",
}

export enum WordStreetArrowDirection {
	RIGHT = "R",
	LEFT = "L",
	UP = "U",
	DOWN = "D",
	UP_RIGHT = "UR",
	UP_LEFT = "UL",
	DOWN_RIGHT = "DR",
	DOWN_LEFT = "DL",
	LEFT_UP = "LU",
	LEFT_DOWN = "LD",
	RIGHT_UP = "RU",
	RIGHT_DOWN = "RD",
	H_LINE = "h_line",
	V_LINE = "v_line",
	TL_LINE = "tl_line",
	TR_LINE = "tr_line",
	BL_LINE = "bl_line",
	BR_LINE = "br_line",
}

export interface PatternData {
	type: Partial<CellType | "">;
	subType?: "char" | "arrow";
	isFirst?: boolean;
	arrow?: WordStreetArrowDirection;
	char?: string;
	number?: number;
	cellNumber?: number;
	revealed?: boolean;
	clues?: ClueType[];
	clueCellNumber?: number;
	invalid?: boolean;
}

export interface IDummyPatternRequestData {
	type: ECrosswordType;
	width: number;
	height: number;
}

export interface IGridPatternsRequestData extends IPaginationApi {
	query?: string;
	type: ECrosswordType;
	minWidth: number;
	maxWidth: number;
	minHeight: number;
	maxHeight: number;
	image: boolean | 1 | 0;
	filterOwn: boolean | 1 | 0;
}

export interface ImportGridPatternRequestData {
	type: ECrosswordType;
	name: string;
	description?: string;
	files: FileList;
}

export interface ICreateGridPatternRequestData {
	type: ECrosswordType;
	name: string;
	description?: string;
	data: PatternData[][];
}

export interface IGridPatternApiEntity {
	id: string;
	type: ECrosswordType;
	name: string;
	description?: string;
	width: number;
	height: number;
	wordCount: number;
	clueCount: number;
	blockCount: number;
	image?: boolean;
	numberOfUses?: number;
	data: PatternData[][];
	createdAt?: string;
	modifiedAt?: string;
	createdById?: string;
	archivedAt: string;
}

// TODO: we might re-enter some values and make them optional here
export interface IUpdateGridPatternRequestData extends IGridPatternApiEntity {
}
