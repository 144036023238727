import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IWordApiAddTagsPayload } from './Word.api.types';
import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';

export type TAddTags = (
	wordId: string,
	data: IWordApiAddTagsPayload,
) => Promise<{ success: boolean }>;

export const addTags: TAddTags = async (wordId, data) => {
	const language = getCurrentLanguage();

	const response = await apiClient.post<{}>(`/rest/${language}/word/${wordId}/tags`, {
		...data,
	});

	return {
		success: Boolean(response.status < 300),
	};
};
